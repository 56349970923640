import React from 'react';

function TermsAndConditions(){
  return(
    <>
    <title>Terms and Conditions</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />
    <div className="">
  <section className="font-medium border-2 border-purple-300">
    <div className="container mx-auto">
      <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
        Terms and Conditions
      </h2>
      <div className="flex mx-auto max-w-max">
        <div id="iub-pp-container">
          <div
            id="iubenda_policy"
            className="iubenda_fluid_policy iubenda_embed_policy iubenda_vip_policy iubenda_terms_policy"
          >
            {" "}
            <div id="wbars_all">
              {" "}
              <div className="iub_container iub_base_container">
                {" "}
                <div id="wbars">
                  {" "}
                  <div className="iub_content legal_pp">
                    {" "}
                    <div className="iub_header">
                      {" "}
                      <h1>
                        Terms and Conditions of <strong>Pago Capital</strong>{" "}
                      </h1>{" "}
                      <p>These Terms govern</p>{" "}
                      <ul>
                        {" "}
                        <li>the use of Pago Capital, and,</li>{" "}
                        <li>
                          any other related Agreement or legal relationship with
                          the Owner
                        </li>{" "}
                      </ul>{" "}
                      <p>
                        in a legally binding way. Capitalized words are defined
                        in the relevant dedicated section of this document.
                      </p>{" "}
                      <br /> <p>The User must read this document carefully.</p>{" "}
                    </div>{" "}
                    <p>Pago Capital is provided by:</p> <p> Pago Capital</p>{" "}
                    <p>
                      <b>Owner contact email:</b> contact@pago.capital{" "}
                    </p>{" "}
                    <hr /> <h2 id="terms-of-use">TERMS OF USE</h2>
                    <p>
                      Unless otherwise specified, the terms of use detailed in
                      this section apply generally when using Pago Capital.
                    </p>{" "}
                    <p>
                      Single or additional conditions of use or access may apply
                      in specific scenarios and in such cases are additionally
                      indicated within this document.
                    </p>{" "}
                    <p>
                      By using Pago Capital, Users confirm to meet the following
                      requirements:
                    </p>{" "}
                    <h3 id="content-on-this_application">
                      Content on Pago Capital
                    </h3>{" "}
                    <p>
                      Unless where otherwise specified or clearly recognizable,
                      all content available on Pago Capital is owned or provided
                      by the Owner or its licensors.
                    </p>{" "}
                    <p>
                      The Owner undertakes its utmost effort to ensure that the
                      content provided on Pago Capital infringes no applicable
                      legal provisions or third-party rights. However, it may
                      not always be possible to achieve such a result.
                      <br /> In such cases, without prejudice to any legal
                      prerogatives of Users to enforce their rights, Users are
                      kindly asked to preferably report related complaints using
                      the contact details provided in this document.
                    </p>{" "}
                    <h3 id="access-to-external-resources">
                      Access to external resources
                    </h3>{" "}
                    <p>
                      Through Pago Capital Users may have access to external
                      resources provided by third parties. Users acknowledge and
                      accept that the Owner has no control over such resources
                      and is therefore not responsible for their content and
                      availability.
                    </p>{" "}
                    <p>
                      Conditions applicable to any resources provided by third
                      parties, including those applicable to any possible grant
                      of rights in content, result from each such third parties’
                      terms and conditions or, in the absence of those,
                      applicable statutory law.
                    </p>{" "}
                    <h3 id="acceptable-use">Acceptable use</h3>{" "}
                    <p>
                      Pago Capital and the Service may only be used within the
                      scope of what they are provided for, under these Terms and
                      applicable law.
                    </p>{" "}
                    <p>
                      Users are solely responsible for making sure that their
                      use of Pago Capital and/or the Service violates no
                      applicable law, regulations or third-party rights.
                    </p>{" "}
                    <h2 id="common-provisions">Common provisions</h2>
                    <h3 id="no-waiver">No Waiver</h3>{" "}
                    <p>
                      The Owner’s failure to assert any right or provision under
                      these Terms shall not constitute a waiver of any such
                      right or provision. No waiver shall be considered a
                      further or continuing waiver of such term or any other
                      term.
                    </p>{" "}
                    <h3 id="service-interruption">Service interruption</h3>{" "}
                    <p>
                      To ensure the best possible service level, the Owner
                      reserves the right to interrupt the Service for
                      maintenance, system updates or any other changes,
                      informing the Users appropriately.
                    </p>{" "}
                    <p>
                      Within the limits of law, the Owner may also decide to
                      suspend or terminate the Service altogether. If the
                      Service is terminated, the Owner will cooperate with Users
                      to enable them to withdraw Personal Data or information in
                      accordance with applicable law.
                    </p>{" "}
                    <p>
                      Additionally, the Service might not be available due to
                      reasons outside the Owner’s reasonable control, such as
                      “force majeure” (eg. labor actions, infrastructural
                      breakdowns or blackouts etc).
                    </p>{" "}
                    <h3 id="service-reselling">Service reselling</h3>{" "}
                    <p>
                      Users may not reproduce, duplicate, copy, sell, resell or
                      exploit any portion of Pago Capital and of its Service
                      without the Owner’s express prior written permission,
                      granted either directly or through a legitimate reselling
                      programme.
                    </p>{" "}
                    <h3 id="intellectual-property-rights">
                      Intellectual property rights
                    </h3>{" "}
                    <p>
                      Without prejudice to any more specific provision of these
                      Terms, any intellectual property rights, such as
                      copyrights, trademark rights, patent rights and design
                      rights related to Pago Capital are the exclusive property
                      of the Owner or its licensors and are subject to the
                      protection granted by applicable laws or international
                      treaties relating to intellectual property.
                    </p>{" "}
                    <p>
                      All trademarks — nominal or figurative — and all other
                      marks, trade names, service marks, word marks,
                      illustrations, images, or logos appearing in connection
                      with Pago Capital are, and remain, the exclusive property
                      of the Owner or its licensors and are subject to the
                      protection granted by applicable laws or international
                      treaties related to intellectual property.
                    </p>{" "}
                    <h3 id="changes-to-these-terms">Changes to these Terms</h3>{" "}
                    <p>
                      The Owner reserves the right to amend or otherwise modify
                      these Terms at any time. In such cases, the Owner will
                      appropriately inform the User of these changes.
                    </p>{" "}
                    <p>
                      Such changes will only affect the relationship with the
                      User for the future.
                    </p>{" "}
                    <p>
                      The continued use of the Service will signify the User’s
                      acceptance of the revised Terms. If Users do not wish to
                      be bound by the changes, they must stop using the Service.
                      Failure to accept the revised Terms, may entitle either
                      party to terminate the Agreement.
                    </p>{" "}
                    <p>
                      The applicable previous version will govern the
                      relationship prior to the User's acceptance. The User can
                      obtain any previous version from the Owner.
                    </p>{" "}
                    <h3 id="assignment-of-contract">Assignment of contract</h3>{" "}
                    <p>
                      The Owner reserves the right to transfer, assign, dispose
                      of by novation, or subcontract any or all rights or
                      obligations under these Terms, taking the User’s
                      legitimate interests into account. Provisions regarding
                      changes of these Terms will apply accordingly.
                    </p>{" "}
                    <p>
                      Users may not assign or transfer their rights or
                      obligations under these Terms in any way, without the
                      written permission of the Owner.
                    </p>{" "}
                    <h3 id="contacts">Contacts</h3>{" "}
                    <p>
                      All communications relating to the use of Pago Capital
                      must be sent using the contact information stated in this
                      document.
                    </p>{" "}
                    <h3 id="severability">Severability</h3>{" "}
                    <p>
                      Should any provision of these Terms be deemed or become
                      invalid or unenforceable under applicable law, the
                      invalidity or unenforceability of such provision shall not
                      affect the validity of the remaining provisions, which
                      shall remain in full force and effect.
                    </p>{" "}
                    <div className="one_line_col">
                      {" "}
                      <div className="box_primary box_10 definitions expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 icon_ribbon">
                          {" "}
                          Definitions and legal references{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <h4>Pago Capital (or this Application)</h4>{" "}
                          <p>
                            The property that enables the provision of the
                            Service.
                          </p>{" "}
                          <h4>Agreement</h4>{" "}
                          <p>
                            Any legally binding or contractual relationship
                            between the Owner and the User, governed by these
                            Terms.
                          </p>{" "}
                          <h4>Owner (or We)</h4>{" "}
                          <p>
                            Indicates the natural person(s) or legal entity that
                            provides Pago Capital and/or the Service to Users.
                          </p>{" "}
                          <h4>Service</h4>{" "}
                          <p>
                            The service provided by Pago Capital as described in
                            these Terms and on Pago Capital.
                          </p>{" "}
                          <h4>Terms</h4>{" "}
                          <p>
                            All provisions applicable to the use of Pago Capital
                            and/or the Service as described in this document,
                            including any other related documents or agreements,
                            and as updated from time to time.
                          </p>{" "}
                          <h4>User (or You)</h4>{" "}
                          <p>
                            Indicates any natural person or legal entity using
                            Pago Capital.
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="iub_footer">
                      {" "}
                      <p> Latest update: April 30, 2022 </p>{" "}
                      <p>
                        {" "}
                        <a
                          target="_top"
                          href="https://www.iubenda.com/en/terms-and-conditions-generator"
                          title="iubenda - Privacy Policy generator"
                        >
                          iubenda
                        </a>{" "}
                        hosts this content and only collects{" "}
                        <a
                          target="_top"
                          href="//www.iubenda.com/privacy-policy/65675001"
                        >
                          the Personal Data strictly necessary
                        </a>{" "}
                        for it to be provided.{" "}
                      </p>{" "}
                    </div>{" "}
                    {/* /footer */}{" "}
                  </div>{" "}
                  {/* /content */}{" "}
                </div>{" "}
                {/* /wbars */}{" "}
              </div>{" "}
              {/* /container base_container */}{" "}
            </div>{" "}
            {/* /wbars_wrapper */}{" "}
          </div>{" "}
          {/* /iubenda_policy */}
        </div>
      </div>
    </div>
  </section>
</div>

    </>
  );
};

export default TermsAndConditions;



// function TermsAndConditions() {
//   (function (w,d) {
//     var loader = function () {
//       var s = d.createElement("script"), 
//       tag = d.getElementsByTagName("script")[0]; 
//   s.src="https://cdn.iubenda.com/iubenda.js"; 
//   tag.parentNode.insertBefore(s,tag);
// }; 
//   if(w.addEventListener){
//     w.addEventListener("load", loader, false);
//   }
//   else if(w.attachEvent){
//     w.attachEvent("onload", loader);
//   }
//   else{w.onload = loader;
//   }
// }
// )(window, document);  
//     return(<>
//         <title>Terms and Conditions</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, shrink-to-fit=no"
//         />
//         <meta name="description" content="" />
//         <meta name="author" content="" />
//         <link rel="preconnect" href="https://fonts.gstatic.com" />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
//         />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
//         />

//         {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/css/pago-favicon.png" /> */}
//         <div className="">
//           <section className="font-medium border-2 border-purple-300">
//             <div className="container mx-auto">
//               <h2 className="py-10 leading-tight font-heading text-9xl md:text-10xl">
//                 Terms and Conditions
//               </h2>
//               <div className="flex mx-auto max-w-max">
//               <a href="https://www.iubenda.com/terms-and-conditions/62836498" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iub-body-embed" title="Terms and Conditions">Terms and Conditions</a>
//               </div>
//             </div>
//           </section>
//         </div>
//       </>
//       );
// };

// export default TermsAndConditions;