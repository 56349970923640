import useAnalyticsEventTracker from './useAnalyticsEventTracker';

function Footer() {
  const gaEventTracker = useAnalyticsEventTracker('Footer');

  const FORM_ENDPOINT = "https://script.google.com/macros/s/AKfycbzqk6XoyH4Olndn-0hN7vaQ3uwrQgGZSJiGrbCouBQdz6-V-mKu1IdGurW5Elz2RfhB/exec";
  
  window.addEventListener("load", function() {
    const form = document.getElementById('newsletter');
    form.addEventListener("submit", function(e) {
      e.preventDefault();
      const data = new FormData(form);
      const action = e.target.action;
      fetch(action, {
        method: 'POST',
        body: data,
      })
      .then(() => {
        alert("Thank you for subscribing!");
        form.reset();
        return false;
      })
    });
  });
  
  return (
  <>
  <div>
  <section className=" bg-purple-50">
  <div className="justify-evenly">
    <div className="container mx-auto">
      <div className="flex flex-wrap justify-evenly">
        <div className="w-auto h-auto px-10 mt-5 mb-5 bg-white border-2 rounded-3xl md:max-w-lg">
          <span className="block mt-10 text-xs font-medium leading-4 tracking-widest uppercase mb-7">
            Newsletter
          </span>
          <form id="newsletter" method="POST" action={FORM_ENDPOINT} target="_blank">
            <h2 className="font-medium text-8xl leading-tighter font-heading mb-7">
              Join the payment revolution.
            </h2>
            <div className="mb-3 lg:flex">
              <input
                className="w-auto px-6 py-3 mb-2 mr-5 border-2 xl:px-9 lg:mb-0 xl:mb-0 xl:mr-2 bg-blueGray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl placeholder-blueGray-300 border-blueGray-200 hover:bg-blueGray-100"
                type="email" name="Email"
                placeholder="Enter your email" required
              />
              <button
                className="inline-block px-10 py-3 text-xl font-medium leading-7 tracking-tighter text-center text-white bg-purple-400 lg:ml-2 lg:w-auto font-heading hover:bg-purple-600 focus:ring-2 focus:ring-purple-500 rounded-xl"
                type="submit" onClick={()=>gaEventTracker('newsletter-signup')}
              >
                Let's go
              </button>
            </div>
            <p className="mb-10 text-xs leading-10 text-darkBlueGray-400">
              You will receive a confirmation email to join our mailing list.
            </p>
          </form>
        </div>
        <div className="w-auto h-auto px-10 mt-5 mb-5 bg-white border-2 md:max-w-lg rounded-3xl">
          <span className="block mt-10 text-xs font-medium leading-4 tracking-widest uppercase mb-7">
            Contact
          </span>
          <h2 className="mb-3 font-medium text-8xl leading-tighter font-heading">
            Have any questions?
          </h2>
          <div className="justify-evenly">
            <a
              className="inline-block px-5 py-3 mb-5 text-xl font-medium leading-7 tracking-tighter text-center text-white bg-purple-400 lg:mx-auto lg:w-auto font-heading focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-xl hover:bg-purple-600"
              href="/contact"
              target="_blank" onClick={()=>gaEventTracker('contact-page-link')}
            >
              Contact Us
            </a>
            <a
              className="inline-block px-10 py-3 mb-5 text-xl font-medium leading-7 tracking-tighter text-center text-white bg-purple-400 lg:mx-auto lg:w-auto font-heading focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-xl hover:bg-purple-600"
              href="/faq"
              target="_blank" onClick={()=>gaEventTracker('faq-page-link')}
            >
              Read FAQs
            </a>
          </div>
          <a
            className="inline-block px-10 py-3 mb-5 text-xl font-medium leading-7 tracking-tighter text-center text-white bg-purple-400 lg:mx-auto lg:w-auto font-heading focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 rounded-xl hover:bg-purple-600"
            onClick={()=>gaEventTracker('pago-bot-link')}
          >
            Chat With Pago Bot (Coming Soon)
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="border-2 border-purple-100">
    <div className="py-4 bg-white">
      <div className="container px-4 mx-auto">
        <div className="lg:flex lg:justify-between lg:items-center">
          <p className="mt-2 font-thin tracking-widest text-gray-400 font-heading">
            © 2022 Pago Capital
          </p>
          <ul className="font-extrabold sm:flex sm:items-center text-blueGray-900">
            <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40">
              <a
                className="hover:opacity-40"
                href="/terms-and-conditions"
                target="_blank" onClick={()=>gaEventTracker('terms-and-conditions')}
              >
                Terms and Conditions
              </a>
            </li>
            <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40">
              <a
                className="hover:opacity-40"
                href="/privacy-policy"
                target="_blank" onClick={()=>gaEventTracker('privacy-policy')}
              >
                Privacy Policy
              </a>
            </li>
            <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40">
              <a
                className="hover:opacity-40"
                href="/cookie-policy"
                target="_blank" onClick={()=>gaEventTracker('cookie-policy')}
              >
                Cookie Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</>);
};

export default Footer;