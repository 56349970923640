import React from 'react';

import errorimage from '../assets/images/error-image.png';

function Error(){
    return (<>
        <title>Error</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />

        {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
        <div className="">
          <section className="font-sans bg-white border-2 border-purple-300">
            <div className="container items-center px-4">
              <div className="flex flex-wrap items-center">
                <div className="items-center w-full px-4 md:w-2/3 lg:w-1/2 lg:mb-0">
                  <span className="block mb-5 tracking-widest text-gray-300 uppercase text-10xl">
                    BAD NEWS
                  </span>
                  <p className="mb-5 text-4xl">We can't find that page.</p>
                  <p className="max-w-md mb-5 text-2xl font-medium xl:mb-16 font-heading">
                    Please try again.
                  </p>
                  <a
                    href="/index.html"
                    className="inline-block px-10 py-5 mb-5 mr-2 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600"
                  >
                    Return Home
                  </a>
                  <a
                    href="/wallet.html"
                    className="inline-block px-10 py-5 mb-5 mr-2 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600"
                  >
                    Get Pago Wallet
                  </a>
                  <a
                    href="/faq.html"
                    className="inline-block px-10 py-5 mb-5 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600"
                  >
                    Explore FAQs
                  </a>
                </div>
                <div className="items-center w-full h-full px-4 mb-20 md:w-1/3 lg:w-1/2 lg:mb-0">
                  <img src={errorimage} alt="404" className="w-full h-full" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
      );
};

export default Error;