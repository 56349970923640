import React from 'react';

function UserGuide(){

    return(<>
        <title>User Guide</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />
        {/* <link rel="stylesheet" href="css/tailwind/tailwind.min.css" />
        <link rel="stylesheet" href="css/main.css" /> */}
        <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" />
        
        <div className="">
          <section className="font-medium border-2 border-purple-300">
            <div className="container mx-auto">
              <h2 className="py-10 leading-tight font-heading text-9xl md:text-10xl">
                Pago Wallet User Guide
              </h2>
              <body>
                <div className="flex mx-auto max-w-max">
                <h2>Pago Wallet User Guide</h2>
<p>Last Update: 3/30/2022</p>
<p>To get started with Pago Wallet, download and set up the Page Wallet app.</p>
<h3>Download</h3>
<p>Before using the Pago Wallet, you must first download and install the Pago Wallet application to your phone.</p>
<ol>
<li>The Pago Wallet supports both Android and iOS phones; the download links are here:</li>
<ol>
<li>Android:&nbsp; <a href="https://appdistribution.firebase.dev/i/2e33e9e6971696fe" target="_blank" rel="noopener">https://appdistribution.firebase.dev/i/2e33e9e6971696fe</a></li>
<li>iPhone: <a href="https://appdistribution.firebase.dev/i/61c01127cd5677af" target="_blank" rel="noopener">https://appdistribution.firebase.dev/i/61c01127cd5677af</a></li>
</ol>
<li>Follow the instructions provided to download and install the Pago Wallet application.</li>
</ol>
<h3>Setup</h3>
<p>After installing the Pago Wallet, use the following steps to configure it.</p>
<ol>
<li>Start the Pago Wallet application.</li>
</ol>
<ol>
<li>Select one of the registration options to create your Pago account (Google, Facebook, Twitter, Login Id, username/password).</li>
<li>Set PIN and note your PIN since you must enter it before signing transactions and viewing the account passphrase.</li>
<li>Create a new Algorand account. Write your passphrase down on paper and store it in a safe location. If something happens to your phone, you can use the passphrase to recover your Algorand account. Also, make a note of the account address.&nbsp;</li>
<li>Use the <a href="https://dispenser.testnet.aws.algodev.network/" target="_blank" rel="noopener">algorand dispenser</a> to fund your account; specify your account address from the previous step.&nbsp;</li>
<li>Click on the menu icon in the upper left of the screen\, and select &ldquo;Account Balances.&rdquo;</li>
<li>Verify that the account balance is 5 Algos.</li>
<li>Click on the menu icon in the upper left of the screen, and select &ldquo;transactions.&rdquo;</li>
<li>In the pending transactions, click on the button that says &ldquo;registration requested.&rdquo;</li>
<li>Accept and sign the registration transaction.</li>
<li>The Pago Wallet setup is now complete.&nbsp;</li>
</ol>
<h3>Send payment</h3>
<p>You can use your Pago Wallet to send payments to friends, family, and businesses.&nbsp;&nbsp;</p>
<ol>
<li>Click on the menu icon, and select &ldquo;Send Payment.&rdquo;</li>
<li>Enter the PayString for the account you want to send the payment. For example:&nbsp; &ldquo;eric809$pagoservices.com&rdquo;.</li>
<li>Enter the amount you&rsquo;d like to send.</li>
<li>Select the asset type that you want to transfer.&nbsp;</li>
<li>Optionally, add a note describing the transaction.&nbsp;</li>
<li>Press the &ldquo;Send&rdquo; button.</li>
<li>Review the transaction details and click on: &ldquo;Yes, send now.&rdquo;</li>
<li>Enter your PIN.</li>
<li>Validate that the transaction was successful.&nbsp;&nbsp;</li>
</ol>
<h3>Request Payment</h3>
<p>You can use your Pago Wallet to request payment from friends, family, and businesses.&nbsp;&nbsp;</p>
<ol>
<li>Click on the menu icon and select &ldquo;Request Payment.&rdquo;</li>
<li>Enter the PayString for the account from which you want to request funds. For example:&nbsp; &ldquo;eric809$pagoservices.com&rdquo;.</li>
<li>Enter the amount you&rsquo;d like to request.</li>
<li>Select the asset type that you want to transfer.&nbsp;</li>
<li>Optionally, add a note describing the transaction.&nbsp;</li>
<li>Press the &ldquo;Request&rdquo; button to send the request. The receiver&rsquo;s wallet will notify them about the payment request.&nbsp;</li>
</ol>
<h3>Check Account Balances</h3>
<p>You can view the account balances for your account.&nbsp;&nbsp;</p>
<ol>
<li>Use the menu buttonand select &ldquo;Account Balances.&rdquo;</li>
<li>You should see your paystring with the Algo amount for your account.</li>
<li>In addition, if you have any other assets, for example, USDC, you should be able to see their balances too.&nbsp;</li>
</ol>
<h3>Account Profile</h3>
<p>Your account profile provides your name, paystring, and email address. In addition, you can view your Algroand account address and PassPhrase.&nbsp;&nbsp;</p>
<ol>
<li>Use the menu button to select &ldquo;Account Profile.&rdquo;</li>
<li>Verify the account information.</li>
<li>Click on the &ldquo;View Account Address&rdquo; to view your Algorand account address. Then click on &ldquo;View Account Mnemonic&rdquo; to view your PassPhrase.&nbsp;</li>
</ol>
<p>Note: Always protect your PassPhrase and don&rsquo;t share it with anyone since the PassPhrase provides access to your Algorand account.</p>
<h3>Settings</h3>
<p>The Settings view lets you update your language, default currency, enable/disable PIN entry, and network connection (Mainnet or Testing).&nbsp; Switching your network connection from Testnet to Mainnet will allow your wallet to handle real value.&nbsp;&nbsp;</p>
<ol>
<li>Use the menu buttonand select &ldquo;Settings.&rdquo;</li>
<li>You can change the default language and currency.&nbsp;</li>
</ol>
<p>Eu</p>
<h3>Switching from TestNet to MainNet</h3>
<ol>
<li>Use the menu button to navigate to Settings.</li>
<li>For &ldquo;Set Network Connection,&rdquo; select &ldquo;MainNet.&rdquo;</li>
<li>Press &ldquo;Save.&rdquo;</li>
<li>You will be logged out and taken to the login screen.</li>
<li>Log back into the application.</li>
<li>The wallet will prompt you to &ldquo;Register a new Algorand account&rdquo; or &ldquo;Register an existing Algorand Account.&rdquo; If you don&rsquo;t already have an account on the mainnet, select a new account; otherwise, use an existing account, and enter the passphrase.</li>
</ol>
<h3>Reading and Displaying QR Codes</h3>
<p>You can display your Algorand account address or PayString as a QR code to share with another Pago Wallet user. You can also read a QR code from another user&rsquo;s Pago Wallet or a Pago-compatible Point of Sale (POS) device.</p>
<ol>
<li>In the upper right corner, click on the QR code graphic</li>
<li>You should see the QR code for the paystring&nbsp;</li>
<li>View the account address QR code by clicking on the &ldquo;account address QR.&rdquo;</li>
</ol>
<h3>Opting Into an Asset</h3>
<p>If you would like to use other cryptocurrencies in addition to Algos, you must first optin to the associated asset.&nbsp;&nbsp;</p>
<ol>
<li>To optin to an asset, choose &ldquo;Send Payment&rdquo; from the menu.</li>
<li>For the receiver, enter your paystring.&nbsp;</li>
<li>For the &ldquo;Amount,&rdquo; enter 0.</li>
<li>For the assetId, select the Asset from the dropdown that you wish to opt-in.</li>
<li>Press &ldquo;Send&rdquo; to submit the transaction.&nbsp;</li>
<li>The successful completion of the transaction concludes the Opt-In process.</li>
</ol>
<h3>Logout</h3>
<p>After using your wallet, log out of your wallet to keep it secure.&nbsp;&nbsp;</p>
<ol>
<li>Use the menu button, then select &ldquo;logout.&rdquo;&nbsp;</li>
</ol>
<h3>Login</h3>
<p>To access your Pago Wallet, you must first log in.&nbsp; You must always use the same login method as when you registered your wallet, for example, Google single sign-on.&nbsp;&nbsp;</p>
<ol>
<li>Click the appropriate login method to log into the Pago Wallet.</li>
</ol>
<h3>Viewing transaction history with the AlgoExplorer</h3>
<ol>
<li>Use the AlgoExplorer within your browser to view the account&rsquo;s transaction history, and enter the account address to see transaction history.&nbsp;</li>
</ol>
<p><a href="https://testnet.algoexplorer.io" target="_blank" rel="noopener">https://testnet.algoexplorer.io</a></p>
<h3>Purchase from the Pago Testnet Hardware Store</h3>
<ol>
<li>Use your browser to open the Hardware Store <a href="http://hardware-store.pago.capital/" target="_blank" rel="noopener">https://demo-website.pagoservices.com/products</a></li>
<li>Choose 1 or 2 items from the store by clicking on the &ldquo;+&rdquo; button to the right of the products.&nbsp;</li>
</ol>
<ol>
<li>Start the checkout process by clicking on &ldquo;Cart&rdquo; at the top left of the screen.</li>
</ol>
<ol>
<li>Click on the &ldquo;Checkout&rdquo; button at the lower left of the screen.</li>
</ol>
<ol>
<li>You will see the QR code, which simulates a physical point of sale (POS) checkout.&nbsp;&nbsp;</li>
<li>Using your Pago Wallet, press the QR code icon at the upper right corner of the screen.</li>
</ol>
<ol>
<li>Select the &ldquo;SCAN CODE&rdquo; Tab, which will enable the phone's camera.</li>
</ol>
<ol>
<li>Scan the QR code displayed on the Hardware Store checkout screen.</li>
<li>Accept the transaction to complete the purchase.&nbsp;</li>
</ol>
                </div>
              </body>
            </div>
          </section>
        </div>
      </>
      );
};

export default UserGuide;