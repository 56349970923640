import hashtag from '../assets/images/hashtag.svg';
import follow from '../assets/images/follow.svg';
import email from '../assets/images/email.svg';

import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';

const Contact = () => {    

  const FORM_ENDPOINT = "https://script.google.com/macros/s/AKfycbwd1_BM_gPKoErVWolIZj3H6C7ScvuILxnrheyqkfrI4nKmmk157H-kcl0Uj5Pe73UJXg/exec";
  

  window.addEventListener("load", function() {
    const form = document.getElementById('contact-form');
    form.addEventListener("submit", function(e) {
      e.preventDefault();
      const data = new FormData(form);
      const action = e.target.action;
      fetch(action, {
        method: 'POST',
        body: data,
      })
      .then(() => {
        alert("Your message has been submitted. We'll be in touch!");
        form.reset();
        return false;
      })
    });
  });

  const gaEventTracker = useAnalyticsEventTracker('Contact');
  
  return (
    <>
      <title>Contact</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
      />
      {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}

      <section className="bg-white rounded-b-10xl">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -mx-4">
      <div className="w-full pt-10 border-2 border-purple-200 lg:w-1/2 bg-purple-50">
        <div className="max-w-lg mx-auto">
        <h2 className="font-medium leading-tight text-9xl md:text-10xl xl:text-11xl font-heading lg:mb-24">
            Contact us
          </h2>
          <div className="max-w-sm py-4 border-b border-gray-200 border-opacity-50">
            <div className="flex items-center">
              <img
                className="h-10 mr-6"
                src={hashtag}
                alt="One Step Projects Hashtag"
              />
              <p className="text-xl font-semibold">
                getpago
              </p>
            </div>
          </div>
          <div className="max-w-sm py-4 border-b border-gray-200 border-opacity-50">
            <div className="flex items-center">
              <img
                className="h-10 mr-6"
                src={follow}
                alt="One Step Projects Social Media"
              />
              <a
                className="text-xl font-semibold"
                href="https://twitter.com/PagoCapital"
                target="_blank" rel="noopener noreferrer" onClick={()=>gaEventTracker('twitter-link')}
              >
                @pagocapital
              </a>
            </div>
          </div>
          <div className="py-4">
            <div className="flex items-center">
              <img
                className="h-10 mr-6"
                src={email}
                alt="Pago Email"
              />
              <a
                className="text-xl font-semibold"
                href="mailto:contact@pago.capital"
                target="_blank" rel="noopener noreferrer" onClick={()=>gaEventTracker('email')}
              >
                contact@pago.capital
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-10 border-2 border-purple-200 lg:w-1/2 bg-purple-50">
          <form id="contact-form" method="POST" action={FORM_ENDPOINT} target="_blank">
          <div className="max-w-lg mx-auto">
            <h2 className="font-medium leading-tight text-9xl md:text-10xl xl:text-11xl font-heading lg:mb-24">
            Message
            </h2>
            <input
              className="w-full px-12 py-5 mb-5 text-xl border-2 border-darkBlueGray-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              type="text" name="Name"
              placeholder="Name" required
            />
            <input
              className="w-full px-12 py-5 mb-5 text-xl border-2 border-darkBlueGray-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              type="email" name="Email"
              placeholder="Email Address" required
            />
            <textarea
              className="w-full h-32 px-12 py-5 mb-10 text-xl border-2 resize-none border-darkBlueGray-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              placeholder="Message" name="Message"
              type="text"
              cols={30}
              rows={10}
              defaultValue={""} required
            />
            <div className="text-left">
              <button type="submit" className="inline-block px-10 py-5 mb-10 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600" onClick={()=>gaEventTracker('message')}>
                Send
              </button>
            </div>
          </div>
          </form>
          </div>
    </div>
  </div>
</section>
</>
)
};

export default Contact;