function VoiceWallet() {
    return(
    <><title>Pago Voice Wallet</title>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" />

    {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" />    */}
    <section className="flex flex-col min-h-screen bg-white rounded-b-10xl pb-10">
    <div className="py-10 leading-tight font-heading text-9xl md:text-10xl">
                  Pago Voice Wallet
                </div>
            <h1 className="py-10"> This is a demonstration of Pago as an interactive wallet that responds to voice and text-based prompts.</h1>
            <iframe title='voice-wallet' className="flex flex-grow w-full h-full" src="https://webui.test.diyva.life/testusera_wallet/index.html" height={"100%"} frameborder="0" allow="microphone; fullscreen" allowfullscreen/>
    </section>
    </>
);
};

export default VoiceWallet;