import React from 'react';

function Faq() {
    return (<>
        <title>FAQs</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />
        {/* <link rel="stylesheet" href="css/tailwind/tailwind.min.css" />
        <link rel="stylesheet" href="css/main.css" /> */}
        <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" />
        <div className="">
          <div>
            <section className="font-medium border-2 border-purple-300">
              <div className="container mx-auto">
                <div className="py-10 leading-tight font-heading text-9xl md:text-10xl">
                  Frequently Asked Questions (FAQs)
                </div>
                <div className="flex mx-auto max-w-max">
                  <div className="w-full px-4">
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 max-w-max rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is Pago?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago is a digital payment platform based on the Algorand
                          blockchain. It makes blockchain-based payments simple and
                          easy. Furthermore, it is:
                        </p>
                        <ul className="text-lg font-normal leading-6 list-disc list-inside">
                          <li>
                            <b>Low cost:</b> Each transaction only costs 1/10th of a
                            cent (USD), which is negligible, especially compared to
                            traditional payment systems like credit cards.
                          </li>
                          <li>
                            <b>Fast:</b> Transactions settle in less than 5 seconds.
                          </li>
                          <li>
                            <b>Scalable:</b> Process thousands of transactions/second.
                          </li>
                          <li>
                            <b>Secure:</b> Significantly reduces the potential for
                            fraud.{" "}
                          </li>
                          <li>
                            <b>Borderless:</b> Payments are global. The location of
                            the sender or recipient does not matter.
                          </li>
                          <li>
                            <b>Inclusive:</b> Anyone with a smartphone can
                            participate.
                          </li>
                          <li>
                            <b>Simple:</b> Easy to use.
                          </li>
                          <li>
                            <b>Efficient:</b> Algorand is an efficient proof-of-stake
                            carbon-neutral blockchain.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is blockchain?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          A Blockchain is a public ledger containing a sequence of
                          transactions organized in blocks, guaranteeing{" "}
                          <b>3 fundamental properties</b>:
                        </p>
                        <ul className="text-lg font-normal leading-6 list-disc list-inside">
                          <li>
                            Everyone can read every block, so the blocks become common
                            knowledge.
                          </li>
                          <li>Everyone can write a transaction in a future block.</li>
                          <li>
                            No one can alter the transactions in a block or the order
                            of the blocks.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is the Algorand blockchain?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Algorand is a foundational blockchain platform founded by
                          Silvio Micali, an MIT professor, Turing Award winner, and
                          zero-knowledge proof creator. Algorand is a Boston-based
                          open-source software company working towards building a
                          borderless economy.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Algorand offers a permissionless, Pure Proof-of-Stake (PoS)
                          protocol with open participation, scalability, security, and
                          transaction finality built on first principles that enable
                          continuous evolution. Algorand’s Mainnet launched on June
                          5th, 2019, and the ecosystem is continuously growing.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 max-w-max rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          Why is Pago based on the Algorand blockchain?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Although Pago is built to be blockchain-agnostic, its pilot
                          product is powered by the Algorand blockchain.
                        </p>
                        <p className="text-lg font-normal leading-6">
                          Pago is based on the Algorand blockchain for 3 key reasons:
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          <strong>1. High speed and low latency:</strong> Algorand has
                          grown from 1,000 TPS to 10,000 TPS, and eventually 48,000
                          TPS. Additional growth is expected with co-chains. It takes
                          less than 5 seconds to commit a transaction.
                        </p>
                        <p className="text-lg font-normal leading-6">
                          <strong>2. Accessible and inexpensive:</strong> Algorand is
                          a public blockchain, built to be borderless with a
                          decentralized trust model. It costs less than 1 cent (USD)
                          per transaction.
                        </p>
                        <p className="text-lg font-normal leading-6">
                          <strong>
                            3. Advanced technology with a global community committed
                            to continuous development:
                          </strong>{" "}
                          Algorand is committed to the continuous development and
                          improvement of its blockchain, with newer and better
                          features. There are limitless opportunities to expand:
                          co-chains, cross-chains, off-chain smart contracts, extended
                          smart contract support, and more.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          <em>
                            Pago makes it easy for consumers, merchants, and payment
                            processors to harness the power of Algorand.
                          </em>
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          We support stable coins for global commerce and promote the
                          seamless growth of Algorand’s transaction volume. Also, we
                          use ALGOs as a utility token rather than a security token!
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How much does Pago cost?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          For individual users, using Pago is free except for a small
                          transaction fee of 1000 microAlgos, which is equivalent to
                          1/10 of 1 US cent ($0.001 USD).
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Business users pay a transaction fee of 1% of the
                          transaction amount.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How fast do transactions settle?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Due to its speed, the Algorand blockchain reaches
                          transaction finality in 4.5 seconds. Therefore the Algorand
                          blockchain settles transactions near real-time, which is
                          great for merchants and payment processors.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is the throughput?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Currently, the Algorand blockchain and Pago support a
                          throughput of over 1,000 transactions per second (TPS).
                          Algorand is working on enhancements to increase the
                          throughput to 10,000 TPS and 48,000 TPS, which Pago will
                          utilize.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is a PayString?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          PayString is a payment identifier protocol developed by
                          Ripple and provides a single ID for anyone who wants to send
                          or receive money. You can register for a unique PayString,{" "}
                          {"{"}username{"}"}$pago.capital, which will allow you and
                          other senders to deposit funds into the Pago wallet App with
                          ease.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is a PassPhrase?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          For Pago and the Algorand blockchain, the passphrase is a
                          sequence of 25 words generated during account creation. A
                          passphrase encodes the public and private keys of an
                          Algorand account, and you can use it to recover the public
                          and private keys. The passphrase is easy to read and
                          transcribe.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          It is crucial to create a physical written copy of your
                          account passphrase and store it securely. Saving a copy of
                          your passphrase will allow you to recover your account and
                          the value it contains in case something happens to your
                          phone or Pago Wallet.
                        </p>
                        <p className="text-lg font-normal leading-6">
                          Note that anyone who has access to your passphrase can also
                          access your account, so make sure to store the written copy
                          of the passphrase securely.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What are stable coins?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Stable coins provide digital representations of traditional
                          fiat currencies, accessible on the blockchain.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          For example, USDC is a fully-reserved stable coin that is
                          always redemeeable 1:1 for US dollars. In other words, 1
                          USDC is always equivalent to 1 US dollar. Consumers can use
                          USDC to send payments and purchase goods using the Algorand
                          blockchain.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What stable coins does Pago support?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago supports the stable coins available on the Algorand
                          blockchain, including:
                        </p>
                        <ul className="text-lg font-normal leading-6 list-disc list-inside">
                          <li>Tether USD (USDT) US dollar</li>
                          <li>USD Coin (USDC) US dollar</li>
                          <li>QCAD (QCAD) Candian dollar</li>
                          <li>Bilira (TRYB) Turkish dollar</li>
                          <li>Euro (eEUR)</li>
                          <li>Swiss franc (eCHF)</li>
                          <li>Swedish krona (eSEK)</li>
                          <li>Brazilian Digital Token (BRZ) Brazilian Real</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How can merchants get started with Pago?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Merchants can get started with Pago by reaching out to our
                          Sales team. Please send an email to sales@pago.capital.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How can payment processors get started with Pago?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Payment Processors can get started with Pago by reaching out
                          to our Sales team. Please send an email to
                          sales@pago.capital.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How does Pago support online transactions?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          When checking out and paying for the purchase, the customer
                          can enter their Pago PayString.
                        </p>{" "}
                        <br />
                        <p className="text-lg font-normal leading-6">
                          This allows the online payment processor to forward a
                          transaction request to the Pago Transaction Gateway, which
                          in turn forwards the transaction to the customer’s Pago
                          Wallet.
                        </p>{" "}
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Once the consumer signs the transaction, the transaction is
                          committed to the blockchain to complete the transaction. The
                          entire process can take as little as 10 seconds.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How can Pago support physical (in-store or kiosk)
                          transactions?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago supports physical (in-store) payments through
                          integrations with existing payment systems.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Customers can use their phones to scan a QR code presented
                          by the Point of Sale (POS) system and sign the transaction
                          using their Pago Wallet to complete the payment.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Pago also supports Kiosk Vendors using a POS system with a
                          Pago Wallet to initiate the exchange transaction. The entire
                          process takes 10 seconds or less.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          Does Pago support Billing?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago is well-suited for billing. Consumers can register
                          their PayString, and businesses can directly issue invoices
                          using the Pago Transaction Gateway. Consumers can then make
                          payments using their Pago Wallet.{" "}
                        </p>
                        <p className="text-lg font-normal leading-6">
                          Businesses and consumers alike can benefit from the speed,
                          security, ease of use, and low cost of Pago.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          Does Pago support Subscriptions?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago is well-suited for subscription payments. Using the
                          Pago Transaction Gateway, businesses can schedule and issue
                          periodic invoices for services to be paid by consumers using
                          their Pago Wallet.{" "}
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Pago also offers per-use Micro Payments, an economical
                          option due to the speed, security, ease of use, and low
                          cost.{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How can I transfer funds from a bank account to Pago?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Transferring funds from a bank account to Pago is secure and
                          straightforward. You can also transfer funds using a credit
                          or debit card.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Simply use the “Fund Transfer” option to open the Wyre
                          widget from the Pago Wallet main menu. Complete the forms to
                          transfer money from a credit/debit card or bank account to
                          Pago.
                        </p>
                        <br />
                        <img
                          src="images/Wyre-transfer.jpg"
                          alt="Wyre Fund Transfer"
                          className="rounded shadow-md"
                        />
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How can I transfer funds from Pago to a bank account?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Transferring funds from a bank account to Pago is secure and
                          straightforward.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Simply use the “Fund Transfer” option to open the Wyre
                          widget from the Pago Wallet main menu. Complete the forms to
                          transfer money from Pago to a bank account.{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          How does Pago support on- and off-ramps for funds?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          On- and off-ramps enable the exchange of blockchain-based
                          cryptocurrencies for fiat currencies held in traditional
                          financial institutions. The ability to move money between
                          the blockchain and traditional financial institutions is
                          essential to provide liquidity to the blockchain.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          To enable on/off-ramps, the Pago Wallet integrates directly
                          with the Wrye exchange, used to move money from a
                          credit/debit card or bank account to the Pago wallet. The
                          Wyre exchange can also move money from the Pago Wallet to a
                          bank account.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Users can also use exchanges like Circle, Coinbase, Binance,
                          and others to transfer funds directly to and from their
                          Algorand blockchain accounts.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex items-start justify-between px-8 mb-12 md:px-14 py-9 rounded-3xl bg-purple-50"
                    >
                      <div>
                        <div className="mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700">
                          What is Pago Coin?
                        </div>
                        <p className="text-lg font-normal leading-6">
                          Pago Coin is a digital asset issued by Pago, used to
                          incentivize the usage of Pago for payments. Both businesses
                          and consumers can participate and earn rewards in Pago
                          coins.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Businesses earn rewards for integrating with Pago to allow
                          customers to pay with Pago. Consumers earn rewards by
                          installing the Pago Wallet and using it for payment
                          transactions.
                        </p>
                        <br />
                        <p className="text-lg font-normal leading-6">
                          Both businesses and consumers can accumulate the Pago Coins
                          to enable participation in future Pago promotions and
                          events. Pago Coins can also be integrated into existing and
                          novel reward systems.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
      );
};

export default Faq;

// import React from 'react';

// function Faq() {
//     return (<>
//         <title>FAQs</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, shrink-to-fit=no"
//         />
//         <meta name="description" content="" />
//         <meta name="author" content="" />
//         <link rel="preconnect" href="https://fonts.gstatic.com" />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
//         />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
//         />
//         {/* <link rel="stylesheet" href="css/tailwind/tailwind.min.css" />
//         <link rel="stylesheet" href="css/main.css" /> */}
//         <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" />
//         <div className="">
//           <div>
//             <section className="font-medium border-2 border-purple-300">
//               <div className="container mx-auto">
//                 <div className="py-10 leading-tight font-heading text-9xl md:text-10xl">
//                   Frequently Asked Questions (FAQs)
//                 </div>
//                 <div className="flex mx-auto max-w-max">
//     <div className="w-full px-4 accordion" id="FaqAccordion">
//   <div className="accordion-item flex items-start justify-between px-8 mb-12 md:px-14 py-9 max-w-max rounded-3xl bg-purple-50">
//     <h2 className="accordion-header mb-0 mb-5 text-3xl cursor-pointer font-heading md:text-5xl xl:text-8xl leading-tighter focus:text-blue-700" id="headingOne">
//       <button
//         className="
//   accordion-button
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseOne"
//         aria-expanded="true"
//         aria-controls="collapseOne"
//       >
//         What is Pago?
//       </button>
//     </h2>
//     <div
//       id="collapseOne"
//       className="accordion-collapse collapse show"
//       aria-labelledby="headingOne"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5 text-lg font-normal leading-6">
//       <p>
//                           Pago is a digital payment platform based on the Algorand
//                           blockchain. It makes blockchain-based payments simple and
//                           easy. Furthermore, it is:
//                         </p>
//                         <ul className="text-lg font-normal leading-6 list-disc list-inside">
//                           <li>
//                             <b>Low cost:</b> Each transaction only costs 1/10th of a
//                             cent (USD), which is negligible, especially compared to
//                             traditional payment systems like credit cards.
//                           </li>
//                           <li>
//                             <b>Fast:</b> Transactions settle in less than 5 seconds.
//                           </li>
//                           <li>
//                             <b>Scalable:</b> Process thousands of transactions/second.
//                           </li>
//                           <li>
//                             <b>Secure:</b> Significantly reduces the potential for
//                             fraud.{" "}
//                           </li>
//                           <li>
//                             <b>Borderless:</b> Payments are global. The location of
//                             the sender or recipient does not matter.
//                           </li>
//                           <li>
//                             <b>Inclusive:</b> Anyone with a smartphone can
//                             participate.
//                           </li>
//                           <li>
//                             <b>Simple:</b> Easy to use.
//                           </li>
//                           <li>
//                             <b>Efficient:</b> Algorand is an efficient proof-of-stake
//                             carbon-neutral blockchain.
//                           </li>
//                         </ul>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingTwo">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseTwo"
//         aria-expanded="false"
//         aria-controls="collapseTwo"
//       >
//         What is blockchain?
//       </button>
//     </h2>
//     <div
//       id="collapseTwo"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingTwo"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           A Blockchain is a public ledger containing a sequence of
//                           transactions organized in blocks, guaranteeing{" "}
//                           <b>3 fundamental properties</b>:
//                         </p>
//                         <ul className="text-lg font-normal leading-6 list-disc list-inside">
//                           <li>
//                             Everyone can read every block, so the blocks become common
//                             knowledge.
//                           </li>
//                           <li>Everyone can write a transaction in a future block.</li>
//                           <li>
//                             No one can alter the transactions in a block or the order
//                             of the blocks.
//                           </li>
//                         </ul>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingThree">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseThree"
//         aria-expanded="false"
//         aria-controls="collapseThree"
//       >
//         What is the Algorand blockchain?
//       </button>
//     </h2>
//     <div
//       id="collapseThree"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingThree"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Algorand is a foundational blockchain platform founded by
//                           Silvio Micali, an MIT professor, Turing Award winner, and
//                           zero-knowledge proof creator. Algorand is a Boston-based
//                           open-source software company working towards building a
//                           borderless economy.
//                         </p>
//                         <br />
//                         <p>
//                           Algorand offers a permissionless, Pure Proof-of-Stake (PoS)
//                           protocol with open participation, scalability, security, and
//                           transaction finality built on first principles that enable
//                           continuous evolution. Algorand’s Mainnet launched on June
//                           5th, 2019, and the ecosystem is continuously growing.
//                         </p>
//       </div>
//     </div>

//     <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingFour">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseFour"
//         aria-expanded="false"
//         aria-controls="collapseFour"
//       >
//         Why is Pago based on the Algorand blockchain?
//       </button>
//     </h2>
//     <div
//       id="collapseFour"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingFour"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Although Pago is built to be blockchain-agnostic, its pilot
//                           product is powered by the Algorand blockchain.
//                         </p>
//                         <p>
//                           Pago is based on the Algorand blockchain for 3 key reasons:
//                         </p>
//                         <br />
//                         <p>
//                           <strong>1. High speed and low latency:</strong> Algorand has
//                           grown from 1,000 TPS to 10,000 TPS, and eventually 48,000
//                           TPS. Additional growth is expected with co-chains. It takes
//                           less than 5 seconds to commit a transaction.
//                         </p>
//                         <p>
//                           <strong>2. Accessible and inexpensive:</strong> Algorand is
//                           a public blockchain, built to be borderless with a
//                           decentralized trust model. It costs less than 1 cent (USD)
//                           per transaction.
//                         </p>
//                         <p>
//                           <strong>
//                             3. Advanced technology with a global community committed
//                             to continuous development:
//                           </strong>{" "}
//                           Algorand is committed to the continuous development and
//                           improvement of its blockchain, with newer and better
//                           features. There are limitless opportunities to expand:
//                           co-chains, cross-chains, off-chain smart contracts, extended
//                           smart contract support, and more.
//                         </p>
//                         <br />
//                         <p>
//                           <em>
//                             Pago makes it easy for consumers, merchants, and payment
//                             processors to harness the power of Algorand.
//                           </em>
//                         </p>
//                         <br />
//                         <p>
//                           We support stable coins for global commerce and promote the
//                           seamless growth of Algorand’s transaction volume. Also, we
//                           use ALGOs as a utility token rather than a security token!
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingFive">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseFive"
//         aria-expanded="false"
//         aria-controls="collapseFive"
//       >
//         How much does Pago cost?
//       </button>
//     </h2>
//     <div
//       id="collapseFive"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingFive"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           For individual users, using Pago is free except for a small
//                           transaction fee of 1000 microAlgos, which is equivalent to
//                           1/10 of 1 US cent ($0.001 USD).
//                         </p>
//                         <br />
//                         <p>
//                           Business users pay a transaction fee of 1% of the
//                           transaction amount.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingSix">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseSix"
//         aria-expanded="false"
//         aria-controls="collapseSix"
//       >
//         How fast do transactions settle?
//       </button>
//     </h2>
//     <div
//       id="collapseSix"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingSix"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Due to its speed, the Algorand blockchain reaches
//                           transaction finality in 4.5 seconds. Therefore the Algorand
//                           blockchain settles transactions near real-time, which is
//                           great for merchants and payment processors.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingSeven">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseSeven"
//         aria-expanded="false"
//         aria-controls="collapseSeven"
//       >
//         What is the throughput?
//       </button>
//     </h2>
//     <div
//       id="collapseSeven"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingSeven"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Currently, the Algorand blockchain and Pago support a
//                           throughput of over 1,000 transactions per second (TPS).
//                           Algorand is working on enhancements to increase the
//                           throughput to 10,000 TPS and 48,000 TPS, which Pago will
//                           utilize.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingEight">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseEight"
//         aria-expanded="false"
//         aria-controls="collapseEight"
//       >
//         What is a PayString?
//       </button>
//     </h2>
//     <div
//       id="collapseEight"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingEight"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           PayString is a payment identifier protocol developed by
//                           Ripple and provides a single ID for anyone who wants to send
//                           or receive money. You can register for a unique PayString,{" "}
//                           {"{"}username{"}"}$pago.capital, which will allow you and
//                           other senders to deposit funds into the Pago wallet App with
//                           ease.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingNine">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseNine"
//         aria-expanded="false"
//         aria-controls="collapseNine"
//       >
//         What is a PassPhrase?
//       </button>
//     </h2>
//     <div
//       id="collapseNine"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingNine"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           For Pago and the Algorand blockchain, the passphrase is a
//                           sequence of 25 words generated during account creation. A
//                           passphrase encodes the public and private keys of an
//                           Algorand account, and you can use it to recover the public
//                           and private keys. The passphrase is easy to read and
//                           transcribe.
//                         </p>
//                         <br />
//                         <p>
//                           It is crucial to create a physical written copy of your
//                           account passphrase and store it securely. Saving a copy of
//                           your passphrase will allow you to recover your account and
//                           the value it contains in case something happens to your
//                           phone or Pago Wallet.
//                         </p>
//                         <p>
//                           Note that anyone who has access to your passphrase can also
//                           access your account, so make sure to store the written copy
//                           of the passphrase securely.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingTen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseTen"
//         aria-expanded="false"
//         aria-controls="collapseTen"
//       >
//         What are stable coins?
//       </button>
//     </h2>
//     <div
//       id="collapseTen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingTen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Stable coins provide digital representations of traditional
//                           fiat currencies, accessible on the blockchain.
//                         </p>
//                         <br />
//                         <p>
//                           For example, USDC is a fully-reserved stable coin that is
//                           always redemeeable 1:1 for US dollars. In other words, 1
//                           USDC is always equivalent to 1 US dollar. Consumers can use
//                           USDC to send payments and purchase goods using the Algorand
//                           blockchain.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingEleven">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseEleven"
//         aria-expanded="false"
//         aria-controls="collapseEleven"
//       >
//         What stable coins does Pago support?
//       </button>
//     </h2>
//     <div
//       id="collapseEleven"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingEleven"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Pago supports the stable coins available on the Algorand
//                           blockchain, including:
//                         </p>
//                         <ul className="text-lg font-normal leading-6 list-disc list-inside">
//                           <li>Tether USD (USDT) US dollar</li>
//                           <li>USD Coin (USDC) US dollar</li>
//                           <li>QCAD (QCAD) Candian dollar</li>
//                           <li>Bilira (TRYB) Turkish dollar</li>
//                           <li>Euro (eEUR)</li>
//                           <li>Swiss franc (eCHF)</li>
//                           <li>Swedish krona (eSEK)</li>
//                           <li>Brazilian Digital Token (BRZ) Brazilian Real</li>
//                         </ul>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingTwelve">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseTwelve"
//         aria-expanded="false"
//         aria-controls="collapseTwelve"
//       >
//         How can merchants get started with Pago?
//       </button>
//     </h2>
//     <div
//       id="collapseTwelve"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingTwelve"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Merchants can get started with Pago by reaching out to our
//                           Sales team. Please send an email to sales@pago.capital.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingThirteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseThirteen"
//         aria-expanded="false"
//         aria-controls="collapseThirteen"
//       >
//         How can payment processors get started with Pago?
//       </button>
//     </h2>
//     <div
//       id="collapseThirteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingThirteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Payment Processors can get started with Pago by reaching out
//                           to our Sales team. Please send an email to
//                           sales@pago.capital.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingFourteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseFourteen"
//         aria-expanded="false"
//         aria-controls="collapseFourteen"
//       >
//         How does Pago support online transactions?
//       </button>
//     </h2>
//     <div
//       id="collapseFourteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingFourteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           When checking out and paying for the purchase, the customer
//                           can enter their Pago PayString.
//                         </p>{" "}
//                         <br />
//                         <p>
//                           This allows the online payment processor to forward a
//                           transaction request to the Pago Transaction Gateway, which
//                           in turn forwards the transaction to the customer’s Pago
//                           Wallet.
//                         </p>{" "}
//                         <br />
//                         <p>
//                           Once the consumer signs the transaction, the transaction is
//                           committed to the blockchain to complete the transaction. The
//                           entire process can take as little as 10 seconds.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingFifteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseFifteen"
//         aria-expanded="false"
//         aria-controls="collapseFifteen"
//       >
//         How can Pago support physical (in-store or kiosk)
//                           transactions?
//       </button>
//     </h2>
//     <div
//       id="collapseFifteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingFifteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Pago supports physical (in-store) payments through
//                           integrations with existing payment systems.
//                         </p>
//                         <br />
//                         <p>
//                           Customers can use their phones to scan a QR code presented
//                           by the Point of Sale (POS) system and sign the transaction
//                           using their Pago Wallet to complete the payment.
//                         </p>
//                         <br />
//                         <p>
//                           Pago also supports Kiosk Vendors using a POS system with a
//                           Pago Wallet to initiate the exchange transaction. The entire
//                           process takes 10 seconds or less.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingSixteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseSixteen"
//         aria-expanded="false"
//         aria-controls="collapseSixteen"
//       >
//         Does Pago support Billing?
//       </button>
//     </h2>
//     <div
//       id="collapseSixteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingSixteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Pago is well-suited for billing. Consumers can register
//                           their PayString, and businesses can directly issue invoices
//                           using the Pago Transaction Gateway. Consumers can then make
//                           payments using their Pago Wallet.{" "}
//                         </p>
//                         <p>
//                           Businesses and consumers alike can benefit from the speed,
//                           security, ease of use, and low cost of Pago.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingSeventeen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseSeventeen"
//         aria-expanded="false"
//         aria-controls="collapseSeventeen"
//       >
//         Does Pago support Subscriptions?
//       </button>
//     </h2>
//     <div
//       id="collapseSeventeen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingSeventeen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Pago is well-suited for subscription payments. Using the
//                           Pago Transaction Gateway, businesses can schedule and issue
//                           periodic invoices for services to be paid by consumers using
//                           their Pago Wallet.{" "}
//                         </p>
//                         <br />
//                         <p>
//                           Pago also offers per-use Micro Payments, an economical
//                           option due to the speed, security, ease of use, and low
//                           cost.{" "}
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingEighteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseEighteen"
//         aria-expanded="false"
//         aria-controls="collapseEighteen"
//       >
//         How can I transfer funds from a bank account to Pago?
//       </button>
//     </h2>
//     <div
//       id="collapseEighteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingEighteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Transferring funds from a bank account to Pago is secure and
//                           straightforward. You can also transfer funds using a credit
//                           or debit card.
//                         </p>
//                         <br />
//                         <p>
//                           Simply use the “Fund Transfer” option to open the Wyre
//                           widget from the Pago Wallet main menu. Complete the forms to
//                           transfer money from a credit/debit card or bank account to
//                           Pago.
//                         </p>
//                         <br />
//                         <img
//                           src="../assets/images/Wyre-transfer.jpg"
//                           alt="Wyre Fund Transfer"
//                           className="rounded shadow-md"
//                         />
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingNineteen">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseNineteen"
//         aria-expanded="false"
//         aria-controls="collapseNineteen"
//       >
//         How can I transfer funds from Pago to a bank account?
//       </button>
//     </h2>
//     <div
//       id="collapseNineteen"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingNineteen"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Transferring funds from a bank account to Pago is secure and
//                           straightforward.
//                         </p>
//                         <br />
//                         <p>
//                           Simply use the “Fund Transfer” option to open the Wyre
//                           widget from the Pago Wallet main menu. Complete the forms to
//                           transfer money from Pago to a bank account.{" "}
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingTwenty">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseTwenty"
//         aria-expanded="false"
//         aria-controls="collapseTwenty"
//       >
//         How does Pago support on- and off-ramps for funds?
//       </button>
//     </h2>
//     <div
//       id="collapseTwenty"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingTwenty"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           On- and off-ramps enable the exchange of blockchain-based
//                           cryptocurrencies for fiat currencies held in traditional
//                           financial institutions. The ability to move money between
//                           the blockchain and traditional financial institutions is
//                           essential to provide liquidity to the blockchain.
//                         </p>
//                         <br />
//                         <p>
//                           To enable on/off-ramps, the Pago Wallet integrates directly
//                           with the Wrye exchange, used to move money from a
//                           credit/debit card or bank account to the Pago wallet. The
//                           Wyre exchange can also move money from the Pago Wallet to a
//                           bank account.
//                         </p>
//                         <br />
//                         <p>
//                           Users can also use exchanges like Circle, Coinbase, Binance,
//                           and others to transfer funds directly to and from their
//                           Algorand blockchain accounts.
//                         </p>
//       </div>
//     </div>
//   </div>

//   <div className="accordion-item bg-white border border-gray-200">
//     <h2 className="accordion-header mb-0" id="headingTwentyone">
//       <button
//         className="
//   accordion-button
//   collapsed
//   relative
//   flex
//   items-center
//   w-full
//   py-4
//   px-5
//   text-base text-gray-800 text-left
//   bg-white
//   border-0
//   rounded-none
//   transition
//   focus:outline-none
// "
//         type="button"
//         data-bs-toggle="collapse"
//         data-bs-target="#collapseTwentyone"
//         aria-expanded="false"
//         aria-controls="collapseTwentyone"
//       >
//         What is Pago Coin?
//       </button>
//     </h2>
//     <div
//       id="collapseTwentyone"
//       className="accordion-collapse collapse"
//       aria-labelledby="headingTwentyone"
//       data-bs-parent="#FaqAccordion"
//     >
//       <div className="accordion-body py-4 px-5">
//       <p>
//                           Pago Coin is a digital asset issued by Pago, used to
//                           incentivize the usage of Pago for payments. Both businesses
//                           and consumers can participate and earn rewards in Pago
//                           coins.
//                         </p>
//                         <br />
//                         <p>
//                           Businesses earn rewards for integrating with Pago to allow
//                           customers to pay with Pago. Consumers earn rewards by
//                           installing the Pago Wallet and using it for payment
//                           transactions.
//                         </p>
//                         <br />
//                         <p>
//                           Both businesses and consumers can accumulate the Pago Coins
//                           to enable participation in future Pago promotions and
//                           events. Pago Coins can also be integrated into existing and
//                           novel reward systems.
//                         </p>
//       </div>
//     </div>
//   </div>
//   </div>
// </div>
//                 </div>
//               </div>
//             </section>
//           </div>
//         </div>
//       </>
//       );
// };

// export default Faq;

