import React from 'react';

function CookiePolicy(){
  return(
    <>
    <title>Cookie Policy</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />
    <div className="container mx-auto">
  <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
    Cookie Policy
  </h2>
  <div className="flex mx-auto max-w-max">
    <div id="iub-pp-container">
      <div
        id="iubenda_policy"
        className="iubenda_fluid_policy iubenda_embed_policy "
      >
        {" "}
        <div id="wbars_all">
          {" "}
          <div className="iub_container iub_base_container">
            {" "}
            <div id="wbars">
              {" "}
              <div className="iub_content legal_pp">
                {" "}
                <div className="one_line_col">
                  {" "}
                  <h2 style={{ paddingTop: 0 }}>
                    {" "}
                    Cookie Policy of Pago Capital{" "}
                  </h2>{" "}
                  <p>
                    {" "}
                    This document informs Users about the technologies that help
                    Pago Capital to achieve the purposes described below. Such
                    technologies allow the Owner to access and store information
                    (for example by using a Cookie) or use resources (for
                    example by running a script) on a User’s device as they
                    interact with Pago Capital.{" "}
                  </p>{" "}
                  <p>
                    {" "}
                    For simplicity, all such technologies are defined as
                    "Trackers" within this document – unless there is a reason
                    to differentiate.
                    <br /> For example, while Cookies can be used on both web
                    and mobile browsers, it would be inaccurate to talk about
                    Cookies in the context of mobile apps as they are a
                    browser-based Tracker. For this reason, within this
                    document, the term Cookies is only used where it is
                    specifically meant to indicate that particular type of
                    Tracker.{" "}
                  </p>{" "}
                  <p>
                    Some of the purposes for which Trackers are used may also
                    require the User's consent. Whenever consent is given, it
                    can be freely withdrawn at any time following the
                    instructions provided in this document.
                  </p>{" "}
                  <p>
                    Pago Capital uses Trackers managed directly by the Owner
                    (so-called “first-party” Trackers) and Trackers that enable
                    services provided by a third-party (so-called “third-party”
                    Trackers). Unless otherwise specified within this document,
                    third-party providers may access the Trackers managed by
                    them.
                    <br /> The validity and expiration periods of Cookies and
                    other similar Trackers may vary depending on the lifetime
                    set by the Owner or the relevant provider. Some of them
                    expire upon termination of the User’s browsing session.
                    <br /> In addition to what’s specified in the descriptions
                    within each of the categories below, Users may find more
                    precise and updated information regarding lifetime
                    specification as well as any other relevant information –
                    such as the presence of other Trackers - in the linked
                    privacy policies of the respective third-party providers or
                    by contacting the Owner.
                  </p>{" "}
                  <p>
                    {" "}
                    To find more information dedicated to Californian consumers
                    and their privacy rights, Users may{" "}
                    <a
                      href="//www.iubenda.com/privacy-policy/62836498/legal?from_cookie_policy=true&no_markup=false"
                      title="Show the complete Privacy Policy"
                      className="show_comp_link iframe-preserve an-preserve"
                    >
                      read the privacy policy
                    </a>
                    .{" "}
                  </p>{" "}
                  <h3>
                    Activities strictly necessary for the operation of Pago
                    Capital and delivery of the Service
                  </h3>{" "}
                  <p>
                    Pago Capital uses so-called “technical” Cookies and other
                    similar Trackers to carry out activities that are strictly
                    necessary for the operation or delivery of the Service.
                  </p>{" "}
                  <h4>First-party Trackers</h4>{" "}
                  <ul className="for_boxes">
                    {" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962603">
                          {" "}
                          Registration and authentication provided directly by
                          Pago Capital{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            By registering or authenticating, Users allow Pago
                            Capital to identify them and give them access to
                            dedicated services. The Personal Data is collected
                            and stored for registration or identification
                            purposes only. The Data collected are only those
                            necessary for the provision of the service requested
                            by the Users.
                          </p>{" "}
                          <h4>Direct registration (Pago Capital)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              The User registers by filling out the registration
                              form and providing the Personal Data directly to
                              Pago Capital.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: city, country, email
                            address, first name, last name, profile picture,
                            Tracker, Usage Data, User ID, username and various
                            types of Data.
                          </p>{" "}
                          <h4>Direct registration (Pago Capital)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              The User registers by filling out the registration
                              form and providing the Personal Data directly to
                              Pago Capital.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: city, country, email
                            address, first name, last name, profile picture,
                            Tracker, Usage Data, User ID, username and various
                            types of Data.
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                  </ul>{" "}
                  <h4>Third-party Trackers</h4>{" "}
                  <ul className="for_boxes">
                    {" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962557">
                          {" "}
                          Platform services and hosting{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            These services have the purpose of hosting and
                            running key components of Pago Capital, therefore
                            allowing the provision of Pago Capital from within a
                            unified platform. Such platforms provide a wide
                            range of tools to the Owner – e.g. analytics, user
                            registration, commenting, database management,
                            e-commerce, payment processing – that imply the
                            collection and handling of Personal Data. <br />{" "}
                            Some of these services work through geographically
                            distributed servers, making it difficult to
                            determine the actual location where the Personal
                            Data are stored.
                          </p>{" "}
                          <h4>WordPress.com (Automattic Inc.)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              WordPress.com is a platform provided by Automattic
                              Inc. that allows the Owner to build, run and host
                              Pago Capital.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Data communicated while
                            using the service, device information, Tracker,
                            Usage Data and various types of Data as specified in
                            the privacy policy of the service.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://automattic.com/privacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962674">
                          {" "}
                          Tag Management{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            This type of service helps the Owner to manage the
                            tags or scripts needed on Pago Capital in a
                            centralized fashion.
                            <br /> This results in the Users' Data flowing
                            through these services, potentially resulting in the
                            retention of this Data.
                          </p>{" "}
                          <h4>Google Tag Manager (Google LLC)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Google Tag Manager is a tag management service
                              provided by Google LLC.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Tracker and Usage Data.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                  </ul>{" "}
                  <h3>Other activities involving the use of Trackers</h3>{" "}
                  <h4>Basic interactions &amp; functionalities</h4>{" "}
                  <p>
                    Pago Capital uses Trackers to enable basic interactions and
                    functionalities, allowing Users to access selected features
                    of the Service and facilitating the User's communication
                    with the Owner.
                  </p>{" "}
                  <ul className="for_boxes">
                    {" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962563">
                          {" "}
                          Registration and authentication{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            By registering or authenticating, Users allow Pago
                            Capital to identify them and give them access to
                            dedicated services.
                            <br /> Depending on what is described below, third
                            parties may provide registration and authentication
                            services. In this case, Pago Capital will be able to
                            access some Data, stored by these third-party
                            services, for registration or identification
                            purposes. <br /> Some of the services listed below
                            may also collect Personal Data for targeting and
                            profiling purposes; to find out more, please refer
                            to the description of each service.
                          </p>{" "}
                          <h4>Facebook Authentication (Facebook, Inc.)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Facebook Authentication is a registration and
                              authentication service provided by Facebook, Inc.
                              and is connected to the Facebook social network.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Tracker and various types
                            of Data as specified in the privacy policy of the
                            service.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://www.facebook.com/help/405977429438260"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                  </ul>{" "}
                  <h4>Experience enhancement</h4>{" "}
                  <p>
                    Pago Capital uses Trackers to provide a personalized user
                    experience by improving the quality of preference management
                    options, and by enabling interaction with external networks
                    and platforms.
                  </p>{" "}
                  <ul className="for_boxes">
                    {" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962477">
                          {" "}
                          Displaying content from external platforms{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            This type of service allows you to view content
                            hosted on external platforms directly from the pages
                            of Pago Capital and interact with them.
                            <br /> This type of service might still collect web
                            traffic data for the pages where the service is
                            installed, even when Users do not use it.
                          </p>{" "}
                          <h4>Google Site Search (Google LLC)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Google Site Search is a search engine embedding
                              service provided by Google LLC that allows Pago
                              Capital to incorporate content of this kind on its
                              pages.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Tracker and Usage Data.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8962521">
                          {" "}
                          Interaction with data collection platforms and other
                          third parties{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            This type of service allows Users to interact with
                            data collection platforms or other services directly
                            from the pages of Pago Capital for the purpose of
                            saving and reusing data.
                            <br /> If one of these services is installed, it may
                            collect browsing and Usage Data in the pages where
                            it is installed, even if the Users do not actively
                            use the service.
                          </p>{" "}
                          <h4>
                            Direct marketing via popups (Pago Capital)
                          </h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Pago Capital uses popups to interact with the User
                              to propose services and products which may be
                              provided by third parties or that are unrelated to
                              the product or services provided by Pago Capital.
                            </p>{" "}
                          </div>{" "}
                          <p>Personal Data processed: Tracker.</p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                  </ul>{" "}
                  <h4>Measurement</h4>{" "}
                  <p>
                    Pago Capital uses Trackers to measure traffic and analyze
                    User behavior with the goal of improving the Service.
                  </p>{" "}
                  <ul className="for_boxes">
                    {" "}
                    <li>
                      {" "}
                      <div className="box_primary box_10 expand">
                        {" "}
                        <h3 className="expand-click w_icon_24 policyicon_purpose_8959376">
                          {" "}
                          Analytics{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <p>
                            The services contained in this section enable the
                            Owner to monitor and analyze web traffic and can be
                            used to keep track of User behavior.
                          </p>{" "}
                          <h4>Google Analytics (Google LLC)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Google Analytics is a web analysis service
                              provided by Google LLC (“Google”). Google utilizes
                              the Data collected to track and examine the use of
                              Pago Capital, to prepare reports on its activities
                              and share them with other Google services.
                              <br /> Google may use the Data collected to
                              contextualize and personalize the ads of its own
                              advertising network.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Tracker and Usage Data.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>{" "}
                            –{" "}
                            <a
                              href="https://tools.google.com/dlpage/gaoptout?hl=en"
                              onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})"
                              target="_blank" rel="noreferrer"
                            >
                              Opt Out
                            </a>
                            .{" "}
                          </p>{" "}
                          <h4>Wordpress Stats (Automattic Inc.)</h4>{" "}
                          <div className="wrap">
                            {" "}
                            <p>
                              Wordpress Stats is an analytics service provided
                              by Automattic Inc.
                            </p>{" "}
                          </div>{" "}
                          <p>
                            Personal Data processed: Tracker and Usage Data.
                          </p>{" "}
                          <p>
                            Place of processing: United States –{" "}
                            <a
                              href="https://automattic.com/privacy/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .{" "}
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </li>{" "}
                  </ul>{" "}
                  <h3 id="manage_cookies">
                    How to manage preferences and provide or withdraw consent
                  </h3>{" "}
                  <p>
                    There are various ways to manage Tracker related preferences
                    and to provide and withdraw consent, where relevant:
                  </p>{" "}
                  <p>
                    Users can manage preferences related to Trackers from
                    directly within their own device settings, for example, by
                    preventing the use or storage of Trackers.
                  </p>{" "}
                  <p>
                    Additionally, whenever the use of Trackers is based on
                    consent, Users can provide or withdraw such consent by
                    setting their preferences within the cookie notice or by
                    updating such preferences accordingly via the relevant
                    consent-preferences widget, if available.
                  </p>{" "}
                  <p>
                    It is also possible, via relevant browser or device
                    features, to delete previously stored Trackers, including
                    those used to remember the User’s initial consent.
                  </p>{" "}
                  <p>
                    Other Trackers in the browser’s local memory may be cleared
                    by deleting the browsing history.
                  </p>{" "}
                  <p>
                    With regard to any third-party Trackers, Users can manage
                    their preferences and withdraw their consent via the related
                    opt-out link (where provided), by using the means indicated
                    in the third party's privacy policy, or by contacting the
                    third party.
                  </p>{" "}
                  <h4>Locating Tracker Settings</h4>{" "}
                  <p>
                    Users can, for example, find information about how to manage
                    Cookies in the most commonly used browsers at the following
                    addresses:
                  </p>{" "}
                  <ul>
                    {" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                      >
                        Google Chrome
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                      >
                        Mozilla Firefox
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                      >
                        Apple Safari
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                      >
                        Microsoft Internet Explorer
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://support.microsoft.com/en-us/help/4027947"
                      >
                        Microsoft Edge
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
                      >
                        Brave
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        rel="noopener nofollow noreferrer"
                        target="_blank"
                        href="https://help.opera.com/en/latest/web-preferences/#cookies"
                      >
                        Opera
                      </a>
                    </li>{" "}
                  </ul>{" "}
                  <p>
                    Users may also manage certain categories of Trackers used on
                    mobile apps by opting out through relevant device settings
                    such as the device advertising settings for mobile devices,
                    or tracking settings in general (Users may open the device
                    settings and look for the relevant setting).
                  </p>{" "}
                  <h4>Consequences of denying consent</h4>{" "}
                  <p>
                    Users are free to decide whether or not to grant consent.
                    However, please note that Trackers help Pago Capital to
                    provide a better experience and advanced functionalities to
                    Users (in line with the purposes outlined in this document).
                    Therefore, in the absence of the User's consent, the Owner
                    may be unable to provide related features.
                  </p>{" "}
                  <h3 id="owner_of_the_data">Owner and Data Controller</h3>{" "}
                  <p>Pago Capital</p>{" "}
                  <p>
                    <strong>Owner contact email:</strong> contact@pago.capital
                  </p>{" "}
                  <p>
                    Since the use of third-party Trackers through Pago Capital
                    cannot be fully controlled by the Owner, any specific
                    references to third-party Trackers are to be considered
                    indicative. In order to obtain complete information, Users
                    are kindly requested to consult the privacy policies of the
                    respective third-party services listed in this document.
                  </p>{" "}
                  <p>
                    Given the objective complexity surrounding tracking
                    technologies, Users are encouraged to contact the Owner
                    should they wish to receive any further information on the
                    use of such technologies by Pago Capital.
                  </p>{" "}
                </div>{" "}
                <div className="one_line_col">
                  {" "}
                  <div className="box_primary box_10 definitions expand">
                    {" "}
                    <h3 className="expand-click w_icon_24 icon_ribbon">
                      {" "}
                      Definitions and legal references{" "}
                    </h3>{" "}
                    <div className="expand-content">
                      {" "}
                      <h4>Personal Data (or Data)</h4>{" "}
                      <p>
                        Any information that directly, indirectly, or in
                        connection with other information — including a personal
                        identification number — allows for the identification or
                        identifiability of a natural person.
                      </p>{" "}
                      <h4>Usage Data</h4>{" "}
                      <p>
                        Information collected automatically through Pago Capital
                        (or third-party services employed in Pago Capital),
                        which can include: the IP addresses or domain names of
                        the computers utilized by the Users who use Pago
                        Capital, the URI addresses (Uniform Resource
                        Identifier), the time of the request, the method
                        utilized to submit the request to the server, the size
                        of the file received in response, the numerical code
                        indicating the status of the server's answer (successful
                        outcome, error, etc.), the country of origin, the
                        features of the browser and the operating system
                        utilized by the User, the various time details per visit
                        (e.g., the time spent on each page within the
                        Application) and the details about the path followed
                        within the Application with special reference to the
                        sequence of pages visited, and other parameters about
                        the device operating system and/or the User's IT
                        environment.
                      </p>{" "}
                      <h4>User</h4>{" "}
                      <p>
                        The individual using Pago Capital who, unless otherwise
                        specified, coincides with the Data Subject.
                      </p>{" "}
                      <h4>Data Subject</h4>{" "}
                      <p>
                        The natural person to whom the Personal Data refers.
                      </p>{" "}
                      <h4>Data Processor (or Data Supervisor)</h4>{" "}
                      <p>
                        The natural or legal person, public authority, agency or
                        other body which processes Personal Data on behalf of
                        the Controller, as described in this privacy policy.
                      </p>{" "}
                      <h4>Data Controller (or Owner)</h4>{" "}
                      <p>
                        The natural or legal person, public authority, agency or
                        other body which, alone or jointly with others,
                        determines the purposes and means of the processing of
                        Personal Data, including the security measures
                        concerning the operation and use of Pago Capital. The
                        Data Controller, unless otherwise specified, is the
                        Owner of Pago Capital.
                      </p>{" "}
                      <h4>Pago Capital (or this Application)</h4>{" "}
                      <p>
                        The means by which the Personal Data of the User is
                        collected and processed.
                      </p>{" "}
                      <h4>Service</h4>{" "}
                      <p>
                        The service provided by Pago Capital as described in the
                        relative terms (if available) and on this
                        site/application.
                      </p>{" "}
                      <h4>European Union (or EU)</h4>{" "}
                      <p>
                        Unless otherwise specified, all references made within
                        this document to the European Union include all current
                        member states to the European Union and the European
                        Economic Area.
                      </p>{" "}
                      <h4>Cookie</h4>{" "}
                      <p>
                        Cookies are Trackers consisting of small sets of data
                        stored in the User's browser.
                      </p>{" "}
                      <h4>Tracker</h4>{" "}
                      <p>
                        Tracker indicates any technology - e.g Cookies, unique
                        identifiers, web beacons, embedded scripts, e-tags and
                        fingerprinting - that enables the tracking of Users, for
                        example by accessing or storing information on the
                        User’s device.
                      </p>{" "}
                      <hr /> <h4>Legal information</h4>{" "}
                      <p>
                        This privacy statement has been prepared based on
                        provisions of multiple legislations, including Art.
                        13/14 of Regulation (EU) 2016/679 (General Data
                        Protection Regulation).
                      </p>{" "}
                      <p>
                        This privacy policy relates solely to Pago Capital, if
                        not stated otherwise within this document.
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="iub_footer">
                  {" "}
                  <p> Latest update: April 30, 2022 </p>{" "}
                  <p>
                    {" "}
                    <a
                      target="_top"
                      href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                      title="iubenda - Privacy Policy generator"
                    >
                      iubenda
                    </a>{" "}
                    hosts this content and only collects{" "}
                    <a
                      target="_top"
                      href="//www.iubenda.com/privacy-policy/65675001"
                    >
                      the Personal Data strictly necessary
                    </a>{" "}
                    for it to be provided.{" "}
                  </p>{" "}
                  <a
                    href="//www.iubenda.com/privacy-policy/62836498/legal?from_cookie_policy=true"
                    className="show_comp_link iframe-preserve an-preserve"
                  >
                    Show the complete Privacy Policy
                  </a>{" "}
                </div>{" "}
                {/* /footer */}
              </div>{" "}
              {/* /content */}{" "}
            </div>{" "}
            {/* /wbars */}{" "}
          </div>{" "}
          {/* /container base_container */}{" "}
        </div>{" "}
        {/* /wbars_wrapper */}{" "}
      </div>{" "}
      {/* /iubenda_policy */}{" "}
    </div>
  </div>
</div>

    </>
  );
};

export default CookiePolicy;



// function CookiePolicy() {
//   (function (w,d) {
//     var loader = function () {
//       var s = d.createElement("script"), 
//       tag = d.getElementsByTagName("script")[0]; 
//   s.src="https://cdn.iubenda.com/iubenda.js"; 
//   tag.parentNode.insertBefore(s,tag);
// }; 
//   if(w.addEventListener){
//     w.addEventListener("load", loader, false);
//   }
//   else if(w.attachEvent){
//     w.attachEvent("onload", loader);
//   }
//   else{w.onload = loader;
//   }
// }
// )(window, document);
//     return(<>
//         <title>Cookie Policy</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, shrink-to-fit=no"
//         />
//         <meta name="description" content="" />
//         <meta name="author" content="" />
//         <link rel="preconnect" href="https://fonts.gstatic.com" />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
//         />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
//         />

//         {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
//         <div className="">
//           <section className="font-medium border-2 border-purple-300">
//             <div className="container mx-auto">
//               <h2 className="py-10 leading-tight font-heading text-9xl md:text-10xl">
//                 Cookie Policy
//               </h2>
//               <div className="flex mx-auto max-w-max">
//                 <a href="https://www.iubenda.com/privacy-policy/62836498/cookie-policy" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iub-body-embed" title="Cookie Policy">Cookie Policy</a>
//               </div>            
//             </div>
//           </section>
//         </div>
//       </>
//       );
// };

// export default CookiePolicy;