import React from 'react';
import Remittanceglobe from "../../assets/images/remittance-globe-edited.png";

function RemittancePayments() {
    return(<>
        <title>Enabling Blockchain-Based Cross-Border Remittance Payments</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />

        {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
        <div className="">
        <section className="pt-5 pb-5 overflow-hidden bg-purple-50">
            <div className="container mx-auto">
              <div className="mb-10 text-center">
                    <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
                    Securely Powering Instant Point-of-Sale (POS) Payments
                    </h2>
              </div>
              <div className="max-w-6xl mx-auto">
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    Sending and receiving remittance payments is a complex process, despite the fact that millions of people around 
                    the world rely on remittances. However, the complexity can be largely eliminated via blockchain-based payments. 
                    The Pago Wallet uses cutting-edge blockchain technology to enable users to quickly, securely, efficiently, and cheaply 
                    send remittances to friends and family worldwide. 
                </p>
              </div>
            </div>
        </section>


        <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
            <div className="container mx-auto">
                <div className="w-full md:w-2/3 mb-4 md:mb-0">
                    <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">What is a Remittance Payment?
                    </h3>
                    <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    A remittance payment is essentially a monetary
                            payment that is transferred from one party to
                            another. Remittance payments provide an
                            <a
                              href="https://www.un.org/development/desa/en/news/population/remittances-matter.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            > essential source of money
                            </a> 
                            for many families around the world. Family members
                            working abroad making high wages can send a portion
                            of their paycheck home, significantly contributing
                            to the family’s income. Remittances represent one of
                            the largest sources of income in low-income and
                            developing nations, in some cases exceeding direct
                            investment and international development assistance.
                    </p>
                    <p className="max-w-4xl mb-5 text-lg leading-10 text-darkBlueGray-400">
                    Remittances also help raise the standard of living
                            in low-income nations and help combat global
                            poverty. According to the World Bank’s 2019
                            Migration and Development Brief, a record-high of
                          <a
                            href="https://www.worldbank.org/en/news/press-release/2019/04/08/record-high-remittances-sent-globally-in-2018"
                            target="_blank"
                            rel="noopener noreferrer"
                          > $529 billion in remittance payments
                          </a> were sent to low and middle-income countries, an
                            increase of 9.6% over the previous year.
                    </p>
                </div>
            </div>
        </section>
        
        <section className="pt-5 pb-5 overflow-hidden bg-purple-50">
            <div className="container mx-auto">
              <div className="mb-10">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Current Challenges With Remittance Payments
                  </h3>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Sending international payments is not only expensive
                            but also extremely slow. Payments can be delayed or
                            canceled if either party does not have a bank
                            account, like 50% of unbanked adults worldwide.
                            Despite the high volume and frequency of
                            transactions,
                          <a
                            href="https://www.investopedia.com/terms/r/remittance.asp"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          </a>
                            presents a considerable hurdle.
                  </p>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  For instance, it can take up to 5 business days to
                            complete a transfer, which makes remittance payments
                            virtually inaccessible in the event of an emergency.
                            Local regulations and fraud detection can add
                            further delays. High transaction costs add
                            additional complications, resulting in significant
                            losses. For example, the global average 
                          <a
                            href="https://medium.com/the-regtech-hub/digital-remittance-a-foundation-for-financial-growth-c2192bef573"
                            target="_blank"
                            rel="noopener noreferrer">
                          </a>
                            is 6.8%, and those costs can exceed 10% when the
                            payment destination is Africa or a Pacific Island.
                  </p>
                </div>
              </div>
        </section>

        <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
          <div className="container mx-auto">
            <div className="mb-10">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Leveraging Blockchain Payments for Global Remittances
                  </h3>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Using the power of blockchain technology, it is now possible to send remittance payments around the world without delays, high costs, or security issues. 
                  Specifically, blockchain-based remittance payments offer the following benefits:
                  </p>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    <ol>
                        <li>
                            <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">1. Reduced Cost and Time</h3>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                            Blockchain promises to reduce the cost and time to
                            send remittances. Remittances sent using the 
                          <a
                            href="https://algorand.foundation/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          </a> can be sent in less than 5 seconds, and the cost of
                            sending a remittance is less than 1/10th of a US
                            cent.
                            </p>
                        </li>
                        <li>
                            <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">2. Enhanced Security and Inclusiveness</h3>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                            In addition to being fast and inexpensive,
                            blockchain is secure, borderless, and inclusive.
                            Anyone with a smartphone can send and receive
                            remittances. Neither a bank account nor a personal
                            identification card is required. This simplifies the
                            transmission and reception of payments for 
                          <a
                            href="https://globalfindex.worldbank.org/sites/globalfindex/files/chapters/2017%20Findex%20full%20report_chapter2.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          </a>
                            , which included 1.7 billion adults worldwide in
                            2017.
                            </p>
                        </li>
                        <li>
                            <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">3. Transparency</h3>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                            Because the Algorand blockchain is public,
                            government agencies can analyze transaction flows,
                            then discover and stop illicit activities.
                            </p>
                        </li>
                        <li>
                            <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">4. Decentralization</h3>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                            Blockchain enables 
                          <a
                            href="https://www.coindesk.com/learn/what-is-defi/"
                            target="_blank"
                            rel="noopener noreferrer">
                              Decentralized Finance (DeFi)</a>
                            , which means that payments don’t rely on central
                            financial intermediaries and gatekeepers like banks.
                            Legacy payment systems, including digital payment
                            options like PayPal, rely on financial institutions
                            that act as “middlemen” and charge sizable fees. A
                            decentralized trust model eliminates the need for
                            middlemen altogether, while enhancing security and
                            giving individuals direct control over their money.
                            </p>
                            
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                            The Pago Wallet makes sending blockchain payments
                            simple, speeding up the process for remittance
                            payments in an accessible and secure platform that
                            is accessible via any smartphone. The payment
                            process is simple, straightforward, and transparent.
                            </p>
                        </li>
                    </ol>
                  </p>
                </div>
            </div>
          </section>

        <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
            <div className="container mx-auto">
              <div className="mb-10">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  How Do Blockchain-Based Remittance Payments Work?
                  </h3>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Using the Pago Wallet, remittance payments can be sent at the blink of an eye. 
                  The process includes the following key steps:
                  </p>
                  <img
                    className="object-cover object-top w-full h-2/3 md:h-full rounded-b-50 md:rounded-bl-none"
                    src={Remittanceglobe}
                    alt="Global remittance payments"
                  />
                </div>
            </div>

            <div className="container mx-auto">
              <div className="max-w-6xl mx-auto">

              <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                      <ul className="text-lg font-normal leading-6 list-disc list-inside">
                        <li>
                          <strong>1.  </strong>To send remittance payments, both the Sender and Receiver must first install the Pago Wallet onto their smartphones.
                        </li>
                        <li>
                          <strong>2.  </strong>The Sender can then use a Crypto Exchange like Coinbase to fund their Algorand account by transferring money from a bank or credit card to their blockchain account.
                        </li>
                        <li>
                          <strong>3. </strong>Once the Sender has funded their account, the Sender can send a remittance to the Receiver.
                          <ol type="a">
                              <li>a. The Sender specifies the amount and the Receiver’s account.</li>
                              <li>b. The transfer will be complete within 5 seconds, and both the Sender and Receiver will be notified when the transaction completes.</li>
                          </ol>
                        </li>
                        <li>
                          <strong>4.  </strong>Once the Receiver has received the remittance, they will see the wallet balance updated to include the remittance.
                        </li>
                        <li>
                          <strong>5.  </strong>To convert their digital balance to the local currency, they can go to their local Exchange Kiosk to exchange their digital currency for cash.
                        </li>
                    </ul>
                </p>
                <p className="max-w-4xl mb-5 text-xl italic leading-10 text-darkBlueGray-400">
                Note that once sufficient numbers of consumers and merchants have adopted the Pago Wallet, digital transactions can be performed directly using the Pago Wallet without converting to the local currency.
                </p>
              </div>
            </div>
          </section>

          <section className="pt-5 pb-5 overflow-hidden bg-purple-50">
            <div className="container mx-auto">
                <div className="mb-10">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Pago Blockchain Payment Platform: Key Components and Actors
                  </h3>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  The Pago blockchain payment system includes several key components, actors, and features that synthesize to enable cross-border payments.
                  The primary components of the Pago Payment Platform are described in the following table.
                  </p>
                  <h4 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Primary Components Supported by the Pago Payment Platform
                  </h4>
                </div>
            <div>
                <table>
                    <tbody>
                        <tr>
      <td>
        <strong>Component</strong>
      </td>
      <td>
        <strong>Usage</strong>
      </td>
    </tr>
    <tr>
      <td>Pago Wallet</td>
      <td>The Pago Wallet is used to send and receive payments.</td>
    </tr>
    <tr>
      <td>Pago Transaction Gateway</td>
      <td>
        The Pago Transaction Gateway routes transaction requests to the correct
        Pago Wallet for signing.
      </td>
    </tr>
    <tr>
      <td>Algorand Blockchain</td>
      <td>
        The Algorand blockchain is a scalable and cost-effective public
        blockchain that provides a public ledger for transactions and account
        balances. Algorand supports stable coins, such as USDC, which is
        equivalent in value to the US dollar.
      </td>
    </tr>
  </tbody>
</table>
</div>
</div>

<div>
<p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  These components are key aspects of all payments completed within the Pago ecosystem. 
                  For remittance payments, in particular, a variety of primary actors are supported, as described in the following table.
                  </p>
</div>

            <div className="max-w-6xl mx-auto">

                  <h4 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Primary Actors in Blockchain-Based Remittance Payments
                  </h4>
                </div>
                
                <div>
                <table>
<tbody>
<tr>
<td><strong>Actor</strong></td>
<td><strong>Role</strong></td>
</tr>
<tr>
<td>Sender</td>
<td>The sender is the person sending the remittance. The sender can end remittances quickly, securely, and with low cost anywhere in the world.</td>
</tr>
<tr>
<td>Receiver</td>
<td>The receiver is the person receiving the remittance. The receiver can receive payments quickly, securely, and at a low cost anywhere in the world.</td>
</tr>
<tr>
<td>Exchange Kiosk Vendor</td>
<td>The exchange kiosk vendor converts digital currency to local cash. The exchange makes a small commission on the conversion. Pago payments provide a new source of income and customers, providing currency conversion from stable coins to local fiat currency.</td>
</tr>
<tr>
<td>Crypto Exchange</td>
<td>The Crypto Exchange transfers value from traditional bank or credit card accounts to blockchain accounts.{" "}</td>
</tr>
</tbody>
</table>
</div>

<div>
<p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Besides the key components and actors described above, the Pago ecosystem offers the following additional features.
                  </p>
</div>
            <div className="max-w-6xl mx-auto">
                  <h4 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                  Additional Features of the Pago Digital Payment Ecosystem
                  </h4>
                </div>
                <div>
                <table border="{1}">
<tbody>
<tr>
<td><strong>Feature</strong></td>
<td><strong>Description</strong></td>
</tr>
<tr>
<td>Stable Coin Support</td>
<td>
<p>For remittance payments, different types of assets (including stable coins) can be transferred. Stable coins are digital currencies that map to actual currencies.{" "}</p>
<p>For example, USDC is a stable coin that maps to the value of the U.S. dollar. Stable coins provide access to a trusted and stable currency, like the U.S. dollar. Merchants and consumers can use stable coins to conduct transactions.{" "}</p>
<p>In the future, the number of available stable coins will increase to include a wide range of regional currencies.</p>
</td>
</tr>
<tr>
<td>Remittance Portal for Scheduled Payments</td>
<td>
<p>Pago is actively developing a Remittance Portal for senders to easily schedule periodic remittance payments. This will be a web-based application that is easy-to-use and secure.{" "}</p>
<p>How will the Remittance Portal Work? According to the schedule defined by the user, the Remittance Portal will generate remittance transactions for the sender to approve using their Pago Wallet. Once approved, the transaction will be committed to the blockchain to complete the transfer of funds.</p>
</td>
</tr>
</tbody>
</table>
</div>

<div>
<p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  These components are key aspects of all payments completed within the Pago ecosystem. 
                  For remittance payments, in particular, a variety of primary actors are supported, as described in the following table.
                  </p>
</div>
            
    </section>


    <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
        <div className="-mt-40 md:-mt-0">
            <div className="container mx-auto">
                <div className="max-w-6xl mx-auto">
                    <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                    Enter a New Era of Remittance Payments
                    </h3>
                    <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    The Pago Payment Platform provides fast, efficient,
                            low-cost, secure, and borderless payments, perfect for
                            remittances. Pago makes it possible to send
                            remittances in 5 seconds, for 1/10th of a cent, to
                            anywhere in the world.
                    </p>
                    <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    <strong> Explore how Pago is changing the <a href="/" target="_blank" rel="noopener noreferrer"> 
                    digital payment experience </a> , and download the <a href="/download-wallet" target="_blank" rel="noopener noreferrer"> Pago Wallet </a> to join the blockchain payment revolution. 
                    Online payments and remittances have never been simpler.</strong>
                    </p>
                    <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                    If you're interested in
                            supporting remittances with Pago, or want to learn
                            more about applications specific to your business,
                            please get in touch.
                    </p>
                </div>
            </div>
        </div>
    </section>
    </div>
    </>
  );
};

export default RemittancePayments;