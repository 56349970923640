import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import logo from "../assets/images/logo.png";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <div className="sticky top-0 z-10 font-sans text-lg font-bold text-black backdrop-filter bg-clip-padding backdrop-blur-xl backdrop-blur-lg bg-opacity-60 firefox:bg-opacity-60">
      <nav className="relative flex justify-between px-6 py-8 bg-purple-100 border-2 border-b border-gray-100 lg:px-12">
        <div className="flex items-center justify-between w-full">
            <a className="flex-shrink-0" href="/">
                <img className="h-12 hover:opacity-40" src={logo} alt="Pago Capital Logo"/>
            </a>
            <div className="hidden md:block">
                <div className="flex items-baseline ml-10 space-x-4">
                    <ul className="hidden px-4 mx-auto text-lg tracking-tight xl:flex">
                        <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40"><a className="text-sm font-medium rounded-md hover:opacity-40" href="/">Home</a></li>
                        <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40"><a className="text-sm font-medium rounded-md hover:opacity-40" href="/download-wallet">Download Wallet</a></li>
                        <li className="relative inline-block text-sm font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-sm font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Use Cases</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-sm font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="/remittance-payments">Cross-Border Remittance Payments</a></li>
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-b hover:bg-purple-400" href="/point-of-sale-payments">Point-of-Sale (POS) Payments</a></li>
                            </ul>
                        </li>
                        <li className="relative inline-block pb-px text-sm font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-sm font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Demos</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                                        </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-sm font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="http://hardware-store.pago.capital/" target="_blank" rel="noopener noreferrer">Hardware Store</a></li>
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md hover:bg-purple-400" href="/voice-wallet" target="_blank" rel="noopener noreferrer">Voice Wallet</a></li>
                                <li className=""><button className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-b hover:bg-purple-400" href="#" target="_blank" rel="noopener noreferrer">Pago Store (coming soon)</button></li>
                            </ul>
                        </li>
                        <li className="relative inline-block pb-px text-sm font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-sm font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Resources</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                                        </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-sm font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md hover:bg-purple-400" href="/user-guide">User Guide</a></li>
                                <li className=""><a className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md hover:bg-purple-400" href="/faq">Frequently-Asked Questions (FAQs)</a></li>
                                <li className=""><button className="block px-4 py-2 text-sm font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="#">Case Studies (coming soon)</button></li>
                            </ul>
                        </li>
                        <li className="pb-px text-sm font-medium border-b border-blue-500 rounded-md mr-11 border-opacity-40">
                            <a className="text-sm font-medium rounded-md hover:opacity-40" href="/contact">Contact Us</a>
                        </li>
                    </ul>
              </div>
            </div>
            <div className="flex -mr-2 md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 text-purple-400 bg-purple-900 rounded-md hover:text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <ul className="hidden px-4 mx-auto text-base text-lg font-medium tracking-tight rounded-md xl:flex">
                        <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40"><a className="text-base font-medium rounded-md hover:opacity-40" href="/">Home</a></li>
                        <li className="pb-px border-b border-blue-500 mr-11 border-opacity-40"><a className="text-base font-medium rounded-md hover:opacity-40" href="/download-wallet">Download Wallet</a></li>
                        <li className="relative inline-block text-base font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-base font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Use Cases</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-base font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="/remittance-payments">Cross-Border Remittance Payments</a></li>
                                <li className=""><a className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-b hover:bg-purple-400" href="/point-of-sale-payments">Point-of-Sale (POS) Payments</a></li>
                            </ul>
                        </li>
                        <li className="relative inline-block pb-px text-base font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-base font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Demos</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                                        </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-base font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="http://hardware-store.pago.capital/" target="_blank" rel="noopener noreferrer">Hardware Store</a></li>
                                <li className=""><a className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md hover:bg-purple-400" href="/voice-wallet" target="_blank" rel="noopener noreferrer">Voice Wallet</a></li>
                                <li className=""><p className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-b hover:bg-purple-400">Pago Store (coming soon)</p></li>
                            </ul>
                        </li>
                        <li className="relative inline-block pb-px text-base font-medium border-b border-blue-500 rounded-md dropdown mr-11 border-opacity-40 group">
                            <button className="inline-flex items-center text-base font-medium font-bold rounded-md hover:opacity-40">
                                <span className="mr-1">Resources</span>
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                                        </svg>
                            </button>
                            <ul className="absolute hidden pt-1 text-base font-medium rounded-md dropdown-menu group-hover:block">
                                <li className=""><a className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md hover:bg-purple-400" href="/faq">Frequently-Asked Questions (FAQs)</a></li>
                                <li className=""><button className="block px-4 py-2 text-base font-medium whitespace-no-wrap bg-purple-200 rounded-md rounded-t hover:bg-purple-400" href="/">Case Studies (coming soon)</button></li>
                            </ul>
                        </li>
                        <li className="pb-px text-base font-medium border-b border-blue-500 rounded-md mr-11 border-opacity-40">
                            <a className="text-base font-medium rounded-md hover:opacity-40" href="/contact">Contact Us</a>
                        </li>
                    </ul>
              </div>
            </div>
          )}
        </Transition>
        </div>
      </nav>
      </div>
    </>
  );
}

export default NavBar;
