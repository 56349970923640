import React from 'react';

function PrivacyPolicy(){
  return(
    <>
    <title>Privacy Policy</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />
    <div className="">
  <section className="font-medium border-2 border-purple-300">
    <div className="container mx-auto">
      <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
        Privacy Policy
      </h2>
      <div className="flex mx-auto max-w-max">
        <div id="iub-pp-container">
          <div
            id="iubenda_policy"
            className="iubenda_fluid_policy iubenda_embed_policy "
          >
            {" "}
            <div id="wbars_all">
              {" "}
              <div className="iub_container iub_base_container">
                {" "}
                <div id="wbars">
                  {" "}
                  <div className="iub_content legal_pp">
                    {" "}
                    <div className="iub_header">
                      {" "}
                      <h1>
                        Privacy Policy of <strong>Pago Capital</strong>
                      </h1>{" "}
                      <p>
                        Pago Capital collects some Personal Data from its Users.
                      </p>
                      <br />{" "}
                      <p>
                        This document contains{" "}
                        <a href="#california_info" target="_self">
                          a section dedicated to Californian consumers and their
                          privacy rights
                        </a>
                        .
                      </p>{" "}
                      <p>
                        This document contains{" "}
                        <a href="#brazil_info" target="_self">
                          a section dedicated to Brazilian Users and their
                          privacy rights
                        </a>
                        .
                      </p>{" "}
                      <p>
                        This document can be printed for reference by using the
                        print command in the settings of any browser.
                      </p>{" "}
                    </div>{" "}
                    {/* /header */}{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="owner_of_the_data">
                        {" "}
                        Owner and Data Controller{" "}
                      </h2>{" "}
                      <p>Pago Capital</p>{" "}
                      <p>
                        <strong>Owner contact email:</strong>{" "}
                        contact@pago.capital
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="types_of_data"> Types of Data collected </h2>{" "}
                      <p>
                        {" "}
                        Among the types of Personal Data that Pago Capital
                        collects, by itself or through third parties, there are:
                        email address; first name; last name; Tracker; Usage
                        Data; Camera permission, without saving or recording;
                        Storage permission; Biometric Data access permission;
                        various types of Data; social media accounts ;
                        geographic position; answers to questions; device
                        information; Data communicated while using the service;
                        username; country; city; profile picture; User ID.{" "}
                      </p>{" "}
                      <p>
                        Complete details on each type of Personal Data collected
                        are provided in the dedicated sections of this privacy
                        policy or by specific explanation texts displayed prior
                        to the Data collection.
                        <br />
                        Personal Data may be freely provided by the User, or, in
                        case of Usage Data, collected automatically when using
                        Pago Capital.
                        <br />
                        Unless specified otherwise, all Data requested by Pago
                        Capital is mandatory and failure to provide this Data
                        may make it impossible for Pago Capital to provide its
                        services. In cases where Pago Capital specifically
                        states that some Data is not mandatory, Users are free
                        not to communicate this Data without consequences to the
                        availability or the functioning of the Service.
                        <br />
                        Users who are uncertain about which Personal Data is
                        mandatory are welcome to contact the Owner.
                        <br />
                        Any use of Cookies – or of other tracking tools – by
                        Pago Capital or by the owners of third-party services
                        used by Pago Capital serves the purpose of providing the
                        Service required by the User, in addition to any other
                        purposes described in the present document and in the
                        Cookie Policy, if available.
                      </p>{" "}
                      <p>
                        Users are responsible for any third-party Personal Data
                        obtained, published or shared through Pago Capital and
                        confirm that they have the third party's consent to
                        provide the Data to the Owner.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="place_of_processing">
                        Mode and place of processing the Data
                      </h2>{" "}
                      <h3>Methods of processing</h3>{" "}
                      <p>
                        The Owner takes appropriate security measures to prevent
                        unauthorized access, disclosure, modification, or
                        unauthorized destruction of the Data.
                        <br />
                        The Data processing is carried out using computers
                        and/or IT enabled tools, following organizational
                        procedures and modes strictly related to the purposes
                        indicated. In addition to the Owner, in some cases, the
                        Data may be accessible to certain types of persons in
                        charge, involved with the operation of Pago Capital
                        (administration, sales, marketing, legal, system
                        administration) or external parties (such as third-party
                        technical service providers, mail carriers, hosting
                        providers, IT companies, communications agencies)
                        appointed, if necessary, as Data Processors by the
                        Owner. The updated list of these parties may be
                        requested from the Owner at any time.
                      </p>{" "}
                      <h3>Legal basis of processing</h3>{" "}
                      <p>
                        The Owner may process Personal Data relating to Users if
                        one of the following applies:
                      </p>{" "}
                      <ul>
                        {" "}
                        <li>
                          Users have given their consent for one or more
                          specific purposes. Note: Under some legislations the
                          Owner may be allowed to process Personal Data until
                          the User objects to such processing (“opt-out”),
                          without having to rely on consent or any other of the
                          following legal bases. This, however, does not apply,
                          whenever the processing of Personal Data is subject to
                          European data protection law;
                        </li>{" "}
                        <li>
                          provision of Data is necessary for the performance of
                          an agreement with the User and/or for any
                          pre-contractual obligations thereof;
                        </li>{" "}
                        <li>
                          processing is necessary for compliance with a legal
                          obligation to which the Owner is subject;
                        </li>{" "}
                        <li>
                          processing is related to a task that is carried out in
                          the public interest or in the exercise of official
                          authority vested in the Owner;
                        </li>{" "}
                        <li>
                          processing is necessary for the purposes of the
                          legitimate interests pursued by the Owner or by a
                          third party.
                        </li>{" "}
                      </ul>{" "}
                      <p>
                        In any case, the Owner will gladly help to clarify the
                        specific legal basis that applies to the processing, and
                        in particular whether the provision of Personal Data is
                        a statutory or contractual requirement, or a requirement
                        necessary to enter into a contract.{" "}
                      </p>{" "}
                      <h3>Place</h3>{" "}
                      <p>
                        The Data is processed at the Owner's operating offices
                        and in any other places where the parties involved in
                        the processing are located.
                        <br />
                        <br /> Depending on the User's location, data transfers
                        may involve transferring the User's Data to a country
                        other than their own. To find out more about the place
                        of processing of such transferred Data, Users can check
                        the section containing details about the processing of
                        Personal Data.
                      </p>{" "}
                      <p>
                        Users are also entitled to learn about the legal basis
                        of Data transfers to a country outside the European
                        Union or to any international organization governed by
                        public international law or set up by two or more
                        countries, such as the UN, and about the security
                        measures taken by the Owner to safeguard their Data.
                        <br />
                        <br /> If any such transfer takes place, Users can find
                        out more by checking the relevant sections of this
                        document or inquire with the Owner using the information
                        provided in the contact section.
                      </p>{" "}
                      <h3>Retention time</h3>{" "}
                      <p>
                        Personal Data shall be processed and stored for as long
                        as required by the purpose they have been collected for.
                      </p>{" "}
                      <p>Therefore:</p>{" "}
                      <ul>
                        {" "}
                        <li>
                          Personal Data collected for purposes related to the
                          performance of a contract between the Owner and the
                          User shall be retained until such contract has been
                          fully performed.
                        </li>{" "}
                        <li>
                          Personal Data collected for the purposes of the
                          Owner’s legitimate interests shall be retained as long
                          as needed to fulfill such purposes. Users may find
                          specific information regarding the legitimate
                          interests pursued by the Owner within the relevant
                          sections of this document or by contacting the Owner.
                        </li>{" "}
                      </ul>{" "}
                      <p>
                        The Owner may be allowed to retain Personal Data for a
                        longer period whenever the User has given consent to
                        such processing, as long as such consent is not
                        withdrawn. Furthermore, the Owner may be obliged to
                        retain Personal Data for a longer period whenever
                        required to do so for the performance of a legal
                        obligation or upon order of an authority.
                        <br />
                        <br /> Once the retention period expires, Personal Data
                        shall be deleted. Therefore, the right of access, the
                        right to erasure, the right to rectification and the
                        right to data portability cannot be enforced after
                        expiration of the retention period.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="use_collected_data">
                        The purposes of processing
                      </h2>{" "}
                      <p>
                        {" "}
                        The Data concerning the User is collected to allow the
                        Owner to provide its Service, comply with its legal
                        obligations, respond to enforcement requests, protect
                        its rights and interests (or those of its Users or third
                        parties), detect any malicious or fraudulent activity,
                        as well as the following: Contacting the User,
                        Analytics, Device permissions for Personal Data access,
                        Social features, Registration and authentication,
                        Managing contacts and sending messages, Displaying
                        content from external platforms, Hosting and backend
                        infrastructure, Infrastructure monitoring, Interaction
                        with data collection platforms and other third parties,
                        Location-based interactions, Managing data collection
                        and online surveys, Platform services and hosting,
                        Registration and authentication provided directly by
                        Pago Capital, SPAM protection and Tag Management.{" "}
                      </p>{" "}
                      <p>
                        For specific information about the Personal Data used
                        for each purpose, the User may refer to the section
                        “Detailed information on the processing of Personal
                        Data”.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="mobile_permissions">
                        Device permissions for Personal Data access
                      </h2>{" "}
                      <p>
                        Depending on the User's specific device, Pago Capital
                        may request certain permissions that allow it to access
                        the User's device Data as described below.
                      </p>{" "}
                      <p>
                        By default, these permissions must be granted by the
                        User before the respective information can be accessed.
                        Once the permission has been given, it can be revoked by
                        the User at any time. In order to revoke these
                        permissions, Users may refer to the device settings or
                        contact the Owner for support at the contact details
                        provided in the present document.
                        <br />
                        The exact procedure for controlling app permissions may
                        be dependent on the User's device and software.
                      </p>{" "}
                      <p>
                        Please note that the revoking of such permissions might
                        impact the proper functioning of Pago Capital.
                      </p>{" "}
                      <p>
                        If User grants any of the permissions listed below, the
                        respective Personal Data may be processed (i.e accessed
                        to, modified or removed) by Pago Capital.
                      </p>{" "}
                      <h3>Biometric Data access permission</h3>{" "}
                      <p>
                        Used for accessing the User's biometrical Data and/or
                        authentication systems, such as for instance, FaceID.
                      </p>{" "}
                      <h3>Camera permission, without saving or recording</h3>{" "}
                      <p>
                        Used for accessing the camera or capturing images and
                        video from the device. <br /> Pago Capital does not save
                        or record the camera output.
                      </p>{" "}
                      <h3>Storage permission</h3>{" "}
                      <p>
                        Used for accessing shared external storage, including
                        the reading and adding of any items.
                      </p>{" "}
                    </div>{" "}
                    <div data-locale="en" />{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="data_processing_detailed_info">
                        Detailed information on the processing of Personal Data
                      </h2>{" "}
                      <p>
                        Personal Data is collected for the following purposes
                        and using the following services:
                      </p>{" "}
                      <ul className="for_boxes">
                        {" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8959376">
                              Analytics
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                The services contained in this section enable
                                the Owner to monitor and analyze web traffic and
                                can be used to keep track of User behavior.
                              </p>{" "}
                              <h4>Google Analytics (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Google Analytics is a web analysis service
                                  provided by Google LLC (“Google”). Google
                                  utilizes the Data collected to track and
                                  examine the use of Pago Capital, to prepare
                                  reports on its activities and share them with
                                  other Google services.
                                  <br /> Google may use the Data collected to
                                  contextualize and personalize the ads of its
                                  own advertising network.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Tracker; Usage Data.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>{" "}
                                –{" "}
                                <a
                                  href="https://tools.google.com/dlpage/gaoptout?hl=en"
                                  target="_blank"  rel="noreferrer"
                                >
                                  Opt Out
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                              <h4>Wordpress Stats (Automattic Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Wordpress Stats is an analytics service
                                  provided by Automattic Inc.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Tracker; Usage Data.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://automattic.com/privacy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8959375">
                              Contacting the User
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <h4>Contact form (Pago Capital)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  By filling in the contact form with their
                                  Data, the User authorizes Pago Capital to use
                                  these details to reply to requests for
                                  information, quotes or any other kind of
                                  request as indicated by the form’s header.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: email address; first
                                name; last name.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers.{" "}
                              </p>{" "}
                              <h4>
                                Mailing list or newsletter (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  By registering on the mailing list or for the
                                  newsletter, the User’s email address will be
                                  added to the contact list of those who may
                                  receive email messages containing information
                                  of commercial or promotional nature concerning
                                  Pago Capital. Your email address might also be
                                  added to this list as a result of signing up
                                  to Pago Capital or after making a purchase.
                                </p>{" "}
                              </div>{" "}
                              <p>Personal Data processed: email address.</p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8959378">
                              Device permissions for Personal Data access
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                Pago Capital requests certain permissions from
                                Users that allow it to access the User's device
                                Data as described below.
                              </p>{" "}
                              <h4>
                                Device permissions for Personal Data access
                                (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital requests certain permissions from
                                  Users that allow it to access the User's
                                  device Data as summarized here and described
                                  within this document.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Biometric Data access
                                permission; Camera permission, without saving or
                                recording; Storage permission.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: biometric information;
                                internet information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962477">
                              Displaying content from external platforms
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service allows you to view content
                                hosted on external platforms directly from the
                                pages of Pago Capital and interact with them.
                                <br /> This type of service might still collect
                                web traffic data for the pages where the service
                                is installed, even when Users do not use it.
                              </p>{" "}
                              <h4>Google Site Search (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Google Site Search is a search engine
                                  embedding service provided by Google LLC that
                                  allows Pago Capital to incorporate content of
                                  this kind on its pages.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Tracker; Usage Data.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962503">
                              Hosting and backend infrastructure
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service has the purpose of hosting
                                Data and files that enable Pago Capital to run
                                and be distributed as well as to provide a
                                ready-made infrastructure to run specific
                                features or parts of Pago Capital.
                              </p>{" "}
                              <p>
                                Some services among those listed below, if any,
                                may work through geographically distributed
                                servers, making it difficult to determine the
                                actual location where the Personal Data are
                                stored.
                              </p>{" "}
                              <h4>
                                Amazon Web Services (AWS) (Amazon Web Services,
                                Inc.)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Amazon Web Services (AWS) is a hosting and
                                  backend service provided by Amazon Web
                                  Services, Inc.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data
                                as specified in the privacy policy of the
                                service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://aws.amazon.com/compliance/data-privacy-faq/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962510">
                              Infrastructure monitoring
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service allows Pago Capital to
                                monitor the use and behavior of its components
                                so its performance, operation, maintenance and
                                troubleshooting can be improved.
                                <br /> Which Personal Data are processed depends
                                on the characteristics and mode of
                                implementation of these services, whose function
                                is to filter the activities of Pago Capital.
                              </p>{" "}
                              <h4>
                                Firebase Performance Monitoring (Google LLC)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Firebase Performance Monitoring is a
                                  monitoring service provided by Google LLC.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data
                                as specified in the privacy policy of the
                                service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962521">
                              Interaction with data collection platforms and
                              other third parties
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service allows Users to interact
                                with data collection platforms or other services
                                directly from the pages of Pago Capital for the
                                purpose of saving and reusing data.
                                <br /> If one of these services is installed, it
                                may collect browsing and Usage Data in the pages
                                where it is installed, even if the Users do not
                                actively use the service.
                              </p>{" "}
                              <h4>
                                Direct marketing via popups (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital uses popups to interact with the
                                  User to propose services and products which
                                  may be provided by third parties or that are
                                  unrelated to the product or services provided
                                  by Pago Capital.
                                </p>{" "}
                              </div>{" "}
                              <p>Personal Data processed: Tracker.</p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962531">
                              Location-based interactions
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <h4>
                                Non-continuous geolocation (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital may collect, use, and share User
                                  location Data in order to provide
                                  location-based services.
                                  <br /> Most browsers and devices provide tools
                                  to opt out from this feature by default. If
                                  explicit authorization has been provided, the
                                  User’s location data may be tracked by Pago
                                  Capital.
                                  <br /> The geographic location of the User is
                                  determined in a manner that isn't continuous,
                                  either at the specific request of the User or
                                  when the User doesn't point out its current
                                  location in the appropriate field and allows
                                  the application to detect the position
                                  automatically.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: geographic position.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: geolocation data.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962454">
                              Managing contacts and sending messages
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service makes it possible to manage
                                a database of email contacts, phone contacts or
                                any other contact information to communicate
                                with the User.
                                <br /> These services may also collect data
                                concerning the date and time when the message
                                was viewed by the User, as well as when the User
                                interacted with it, such as by clicking on links
                                included in the message.
                              </p>{" "}
                              <h4>Firebase Notifications (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Firebase Notifications is a message sending
                                  service provided by Google LLC. Firebase
                                  Notifications can be integrated with Firebase
                                  Analytics to target analytics-based audiences
                                  and track opening and conversion events.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data
                                as specified in the privacy policy of the
                                service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962540">
                              Managing data collection and online surveys
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service allows Pago Capital to
                                manage the creation, deployment, administration,
                                distribution and analysis of online forms and
                                surveys in order to collect, save and reuse Data
                                from any responding Users.
                                <br /> The Personal Data collected depend on the
                                information asked and provided by the Users in
                                the corresponding online form.
                              </p>{" "}
                              <p>
                                These services may be integrated with a wide
                                range of third-party services to enable the
                                Owner to take subsequent steps with the Data
                                processed - e.g. managing contacts, sending
                                messages, analytics, advertising and payment
                                processing.
                              </p>{" "}
                              <h4>
                                Data provided via online forms, managed directly
                                (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital makes use of online forms that
                                  can be used to collect Data about Users.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: answers to questions.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: inferred information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962546">
                              Platform services and hosting
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                These services have the purpose of hosting and
                                running key components of Pago Capital,
                                therefore allowing the provision of Pago Capital
                                from within a unified platform. Such platforms
                                provide a wide range of tools to the Owner –
                                e.g. analytics, user registration, commenting,
                                database management, e-commerce, payment
                                processing – that imply the collection and
                                handling of Personal Data. <br /> Some of these
                                services work through geographically distributed
                                servers, making it difficult to determine the
                                actual location where the Personal Data are
                                stored.
                              </p>{" "}
                              <h4>Apple App Store (Apple Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital is distributed on Apple's App
                                  Store, a platform for the distribution of
                                  mobile apps, provided by Apple Inc.
                                </p>{" "}
                                <p>
                                  By virtue of being distributed via this app
                                  store, Apple collects basic analytics and
                                  provides reporting features that enables the
                                  Owner to view usage analytics data and measure
                                  the performance of Pago Capital. Much of this
                                  information is processed on an opt-in basis.
                                </p>{" "}
                                <p>
                                  Users may opt-out of this analytics feature
                                  directly through their device settings. More
                                  information on how to manage analysis settings
                                  can be found on{" "}
                                  <a href="https://support.apple.com/en-us/HT202100">
                                    this page
                                  </a>
                                  .
                                </p>{" "}
                              </div>{" "}
                              <p>Personal Data processed: Usage Data.</p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://www.apple.com/legal/privacy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <h4>Google Play Store (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital is distributed on the Google Play
                                  Store, a platform for the distribution of
                                  mobile apps, provided by Google LLC.
                                </p>{" "}
                                <p>
                                  By virtue of being distributed via this app
                                  store, Google collects usage and diagnostics
                                  data and share aggregate information with the
                                  Owner. Much of this information is processed
                                  on an opt-in basis.
                                </p>{" "}
                                <p>
                                  Users may opt-out of this analytics feature
                                  directly through their device settings. More
                                  information on how to manage analysis settings
                                  can be found on{" "}
                                  <a href="https://support.google.com/accounts/answer/6078260">
                                    this page
                                  </a>
                                  .
                                </p>{" "}
                              </div>{" "}
                              <p>Personal Data processed: Usage Data.</p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <h4>WordPress.com (Automattic Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  WordPress.com is a platform provided by
                                  Automattic Inc. that allows the Owner to
                                  build, run and host Pago Capital.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Data communicated while
                                using the service; device information; Tracker;
                                Usage Data; various types of Data as specified
                                in the privacy policy of the service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://automattic.com/privacy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962448">
                              Registration and authentication
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                By registering or authenticating, Users allow
                                Pago Capital to identify them and give them
                                access to dedicated services.
                                <br /> Depending on what is described below,
                                third parties may provide registration and
                                authentication services. In this case, Pago
                                Capital will be able to access some Data, stored
                                by these third-party services, for registration
                                or identification purposes. <br /> Some of the
                                services listed below may also collect Personal
                                Data for targeting and profiling purposes; to
                                find out more, please refer to the description
                                of each service.
                              </p>{" "}
                              <h4>Firebase Authentication (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Firebase Authentication is a registration and
                                  authentication service provided by Google LLC.
                                  To simplify the registration and
                                  authentication process, Firebase
                                  Authentication can make use of third-party
                                  identity providers and save the information on
                                  its platform.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: email address; first
                                name; last name; social media accounts .
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers; internet
                                information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                              <h4>Facebook Authentication (Facebook, Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Facebook Authentication is a registration and
                                  authentication service provided by Facebook,
                                  Inc. and is connected to the Facebook social
                                  network.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Tracker; various types
                                of Data as specified in the privacy policy of
                                the service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://www.facebook.com/help/405977429438260"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                              <h4>Google OAuth (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Google OAuth is a registration and
                                  authentication service provided by Google LLC
                                  and is connected to the Google network.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data
                                as specified in the privacy policy of the
                                service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                              <h4>Twitter OAuth (Twitter, Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Twitter Oauth is a registration and
                                  authentication service provided by Twitter,
                                  Inc. and is connected to the Twitter social
                                  network.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data
                                as specified in the privacy policy of the
                                service.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://dev.twitter.com/terms/api-terms"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962603">
                              Registration and authentication provided directly
                              by Pago Capital
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                By registering or authenticating, Users allow
                                Pago Capital to identify them and give them
                                access to dedicated services. The Personal Data
                                is collected and stored for registration or
                                identification purposes only. The Data collected
                                are only those necessary for the provision of
                                the service requested by the Users.
                              </p>{" "}
                              <h4>Direct registration (Pago Capital)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  The User registers by filling out the
                                  registration form and providing the Personal
                                  Data directly to Pago Capital.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: city; country; email
                                address; first name; last name; profile picture;
                                Tracker; Usage Data; User ID; username; various
                                types of Data.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers; internet
                                information; sensorial information.{" "}
                              </p>{" "}
                              <h4>Direct registration (Pago Capital)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  The User registers by filling out the
                                  registration form and providing the Personal
                                  Data directly to Pago Capital.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: city; country; email
                                address; first name; last name; profile picture;
                                Tracker; Usage Data; User ID; username; various
                                types of Data.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers; internet
                                information; sensorial information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962409">
                              Social features
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <h4>
                                Inviting and suggesting friends (Pago Capital)
                              </h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital may use the Personal Data
                                  provided to allow Users to invite their
                                  friends - for example through the address
                                  book, if access has been provided - and to
                                  suggest friends or connections inside it.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: various types of Data.
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <h4>Public profile (Pago Capital)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Users may have public profiles that other
                                  Users can display. In addition to the Personal
                                  Data provided, this profile may contain Users'
                                  interactions with Pago Capital.
                                </p>{" "}
                              </div>{" "}
                              <p>Personal Data processed: username.</p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962659">
                              SPAM protection
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service analyzes the traffic of
                                Pago Capital, potentially containing Users'
                                Personal Data, with the purpose of filtering it
                                from parts of traffic, messages and content that
                                are recognized as SPAM.
                              </p>{" "}
                              <h4>Akismet (Automattic Inc.)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Akismet is a SPAM protection service provided
                                  by Automattic Inc.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: email address; Usage
                                Data; username.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://automattic.com/privacy-notice/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: identifiers; internet
                                information.{" "}
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 policyicon_purpose_8962674">
                              Tag Management
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <p>
                                This type of service helps the Owner to manage
                                the tags or scripts needed on Pago Capital in a
                                centralized fashion.
                                <br /> This results in the Users' Data flowing
                                through these services, potentially resulting in
                                the retention of this Data.
                              </p>{" "}
                              <h4>Google Tag Manager (Google LLC)</h4>{" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Google Tag Manager is a tag management service
                                  provided by Google LLC.
                                </p>{" "}
                              </div>{" "}
                              <p>
                                Personal Data processed: Tracker; Usage Data.
                              </p>{" "}
                              <p>
                                Place of processing: United States –{" "}
                                <a
                                  href="https://policies.google.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .{" "}
                              </p>{" "}
                              <p>
                                {" "}
                                Category of personal information collected
                                according to CCPA: internet information.{" "}
                              </p>{" "}
                              <p>
                                This processing constitutes a sale based on the
                                definition under the CCPA. In addition to the
                                information in this clause, the User can find
                                information regarding how to opt out of the sale
                                in the section detailing the rights of
                                Californian consumers.
                              </p>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                      </ul>{" "}
                    </div>{" "}
                    <div data-locale="en" />{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="further_data">
                        Further information about the processing of Personal
                        Data
                      </h2>{" "}
                      <ul className="for_boxes">
                        {" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 icon_general">
                              Push notifications
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital may send push notifications to
                                  the User to achieve the purposes outlined in
                                  this privacy policy.
                                </p>{" "}
                                <p>
                                  Users may in most cases opt-out of receiving
                                  push notifications by visiting their device
                                  settings, such as the notification settings
                                  for mobile phones, and then change those
                                  settings for Pago Capital, some or all of the
                                  apps on the particular device.
                                  <br /> Users must be aware that disabling push
                                  notifications may negatively affect the
                                  utility of Pago Capital.
                                </p>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 icon_general">
                              Unique device identification
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital may track Users by storing a
                                  unique identifier of their device, for
                                  analytics purposes or for storing Users'
                                  preferences.
                                </p>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          <div className="box_primary box_10 expand">
                            {" "}
                            <h3 className="expand-click w_icon_24 icon_general">
                              User identification via a universally unique
                              identifier (UUID)
                            </h3>{" "}
                            <div className="expand-content">
                              {" "}
                              <div className="wrap">
                                {" "}
                                <p>
                                  Pago Capital may track Users by storing a
                                  so-called universally unique identifier (or
                                  short UUID) for analytics purposes or for
                                  storing Users' preferences. This identifier is
                                  generated upon installation of this
                                  Application, it persists between Application
                                  launches and updates, but it is lost when the
                                  User deletes the Application. A reinstall
                                  generates a new UUID.
                                </p>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </li>{" "}
                      </ul>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="rights_subjects">The rights of Users</h2>{" "}
                      <p>
                        Users may exercise certain rights regarding their Data
                        processed by the Owner.
                      </p>{" "}
                      <p>
                        In particular, Users have the right to do the following:
                      </p>{" "}
                      <ul>
                        {" "}
                        <li>
                          <strong>Withdraw their consent at any time.</strong>{" "}
                          Users have the right to withdraw consent where they
                          have previously given their consent to the processing
                          of their Personal Data.
                        </li>{" "}
                        <li>
                          <strong>Object to processing of their Data.</strong>{" "}
                          Users have the right to object to the processing of
                          their Data if the processing is carried out on a legal
                          basis other than consent. Further details are provided
                          in the dedicated section below.
                        </li>{" "}
                        <li>
                          <strong>Access their Data.</strong> Users have the
                          right to learn if Data is being processed by the
                          Owner, obtain disclosure regarding certain aspects of
                          the processing and obtain a copy of the Data
                          undergoing processing.
                        </li>{" "}
                        <li>
                          <strong>Verify and seek rectification.</strong> Users
                          have the right to verify the accuracy of their Data
                          and ask for it to be updated or corrected.
                        </li>{" "}
                        <li>
                          <strong>
                            Restrict the processing of their Data.
                          </strong>{" "}
                          Users have the right, under certain circumstances, to
                          restrict the processing of their Data. In this case,
                          the Owner will not process their Data for any purpose
                          other than storing it.{" "}
                        </li>{" "}
                        <li>
                          <strong>
                            Have their Personal Data deleted or otherwise
                            removed.
                          </strong>{" "}
                          Users have the right, under certain circumstances, to
                          obtain the erasure of their Data from the Owner.
                        </li>{" "}
                        <li>
                          <strong>
                            Receive their Data and have it transferred to
                            another controller.
                          </strong>{" "}
                          Users have the right to receive their Data in a
                          structured, commonly used and machine readable format
                          and, if technically feasible, to have it transmitted
                          to another controller without any hindrance. This
                          provision is applicable provided that the Data is
                          processed by automated means and that the processing
                          is based on the User's consent, on a contract which
                          the User is part of or on pre-contractual obligations
                          thereof.
                        </li>{" "}
                        <li>
                          <strong>Lodge a complaint.</strong> Users have the
                          right to bring a claim before their competent data
                          protection authority.
                        </li>{" "}
                      </ul>{" "}
                      <h3>Details about the right to object to processing</h3>{" "}
                      <p>
                        Where Personal Data is processed for a public interest,
                        in the exercise of an official authority vested in the
                        Owner or for the purposes of the legitimate interests
                        pursued by the Owner, Users may object to such
                        processing by providing a ground related to their
                        particular situation to justify the objection.
                      </p>{" "}
                      <p>
                        Users must know that, however, should their Personal
                        Data be processed for direct marketing purposes, they
                        can object to that processing at any time without
                        providing any justification. To learn, whether the Owner
                        is processing Personal Data for direct marketing
                        purposes, Users may refer to the relevant sections of
                        this document.{" "}
                      </p>{" "}
                      <h3>How to exercise these rights</h3>{" "}
                      <p>
                        Any requests to exercise User rights can be directed to
                        the Owner through the contact details provided in this
                        document. These requests can be exercised free of charge
                        and will be addressed by the Owner as early as possible
                        and always within one month.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="cookie_policy">Cookie Policy</h2>{" "}
                      <p>
                        Pago Capital uses Trackers. To learn more, the User may
                        consult the{" "}
                        <a
                          href="//www.iubenda.com/privacy-policy/62836498/cookie-policy"
                          title="Cookie Policy"
                          className="show_comp_link iframe-preserve an-preserve"
                        >
                          Cookie Policy
                        </a>
                        .
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="further_data_processing_info">
                        Additional information about Data collection and
                        processing
                      </h2>{" "}
                      <h3>Legal action</h3>{" "}
                      <p>
                        The User's Personal Data may be used for legal purposes
                        by the Owner in Court or in the stages leading to
                        possible legal action arising from improper use of Pago
                        Capital or the related Services.
                        <br />
                        The User declares to be aware that the Owner may be
                        required to reveal personal data upon request of public
                        authorities.
                      </p>{" "}
                      <h3>
                        Additional information about User's Personal Data
                      </h3>{" "}
                      <p>
                        In addition to the information contained in this privacy
                        policy, Pago Capital may provide the User with
                        additional and contextual information concerning
                        particular Services or the collection and processing of
                        Personal Data upon request.
                      </p>{" "}
                      <h3>System logs and maintenance</h3>{" "}
                      <p>
                        For operation and maintenance purposes, Pago Capital and
                        any third-party services may collect files that record
                        interaction with Pago Capital (System logs) use other
                        Personal Data (such as the IP Address) for this purpose.
                      </p>{" "}
                      <h3>Information not contained in this policy</h3>{" "}
                      <p>
                        More details concerning the collection or processing of
                        Personal Data may be requested from the Owner at any
                        time. Please see the contact information at the
                        beginning of this document.
                      </p>{" "}
                      <h3>How “Do Not Track” requests are handled</h3>{" "}
                      <p>
                        Pago Capital does not support “Do Not Track” requests.
                        <br />
                        To determine whether any of the third-party services it
                        uses honor the “Do Not Track” requests, please read
                        their privacy policies.
                      </p>{" "}
                      <h3>Changes to this privacy policy</h3>{" "}
                      <p>
                        The Owner reserves the right to make changes to this
                        privacy policy at any time by notifying its Users on
                        this page and possibly within Pago Capital and/or - as
                        far as technically and legally feasible - sending a
                        notice to Users via any contact information available to
                        the Owner. It is strongly recommended to check this page
                        often, referring to the date of the last modification
                        listed at the bottom. <br />
                        <br /> Should the changes affect processing activities
                        performed on the basis of the User’s consent, the Owner
                        shall collect new consent from the User, where required.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="california_info">
                        Information for Californian consumers
                      </h2>{" "}
                      <p>
                        This part of the document integrates with and
                        supplements the information contained in the rest of the
                        privacy policy and is provided by the business running
                        Pago Capital and, if the case may be, its parent,
                        subsidiaries and affiliates (for the purposes of this
                        section referred to collectively as “we”, “us”, “our”).
                      </p>{" "}
                      <p>
                        The provisions contained in this section apply to all
                        Users who are consumers residing in the state of
                        California, United States of America, according to "The
                        California Consumer Privacy Act of 2018" (Users are
                        referred to below, simply as “you”, “your”, “yours”),
                        and, for such consumers, these provisions supersede any
                        other possibly divergent or conflicting provisions
                        contained in the privacy policy.
                      </p>{" "}
                      <p>
                        This part of the document uses the term “personal
                        information“ as it is defined in The California Consumer
                        Privacy Act (CCPA).
                      </p>{" "}
                      <h3>
                        Categories of personal information collected, disclosed
                        or sold
                      </h3>{" "}
                      <p>
                        In this section we summarize the categories of personal
                        information that we've collected, disclosed or sold and
                        the purposes thereof.{" "}
                        <strong>
                          You can read about these activities in detail in the
                          section titled “Detailed information on the processing
                          of Personal Data” within this document.
                        </strong>
                      </p>{" "}
                      <h4>
                        Information we collect: the categories of personal
                        information we collect
                      </h4>{" "}
                      <p>
                        We have collected the following categories of personal
                        information about you: identifiers, biometric
                        information, internet information, geolocation data,
                        sensorial information and inferred information.
                      </p>{" "}
                      <p>
                        We will not collect additional categories of personal
                        information without notifying you.
                      </p>{" "}
                      <h4>
                        How we collect information: what are the sources of the
                        personal information we collect?
                      </h4>{" "}
                      <p>
                        We collect the above mentioned categories of personal
                        information, either directly or indirectly, from you
                        when you use Pago Capital.
                      </p>{" "}
                      <p>
                        For example, you directly provide your personal
                        information when you submit requests via any forms on
                        Pago Capital. You also provide personal information
                        indirectly when you navigate Pago Capital, as personal
                        information about you is automatically observed and
                        collected. Finally, we may collect your personal
                        information from third parties that work with us in
                        connection with the Service or with the functioning of
                        Pago Capital and features thereof.
                      </p>{" "}
                      <h4>
                        How we use the information we collect: sharing and
                        disclosing of your personal information with third
                        parties for a business purpose
                      </h4>{" "}
                      <p>
                        We may disclose the personal information we collect
                        about you to a third party for business purposes. In
                        this case, we enter a written agreement with such third
                        party that requires the recipient to both keep the
                        personal information confidential and not use it for any
                        purpose(s) other than those necessary for the
                        performance of the agreement.
                      </p>{" "}
                      <p>
                        We may also disclose your personal information to third
                        parties when you explicitly ask or authorize us to do
                        so, in order to provide you with our Service.
                      </p>{" "}
                      <p>
                        To find out more about the purposes of processing,
                        please refer to the relevant section of this document.
                      </p>{" "}
                      <h4>Sale of your personal information</h4>{" "}
                      <p>
                        For our purposes, the word “sale” means any “selling,
                        renting, releasing, disclosing, disseminating, making
                        available, transferring or otherwise communicating
                        orally, in writing, or by electronic means, a consumer's
                        personal information by the business to{" "}
                        <strong>
                          another business or a third party, for monetary or
                          other valuable consideration
                        </strong>
                        ”.
                      </p>{" "}
                      <p>
                        This means that, for example, a sale can happen whenever
                        an application runs ads, or makes statistical analyses
                        on the traffic or views, or simply because it uses tools
                        such as social network plugins and the like.
                      </p>{" "}
                      <h4>
                        Your right to opt out of the sale of personal
                        information
                      </h4>{" "}
                      <p>
                        You have the right to opt out of the sale of your
                        personal information. This means that whenever you
                        request us to stop selling your data, we will abide by
                        your request.
                        <br />
                        Such requests can be made freely, at any time, without
                        submitting any verifiable request, simply by following
                        the instructions below.
                      </p>{" "}
                      <h4>
                        Instructions to opt out of the sale of personal
                        information
                      </h4>{" "}
                      <p>
                        If you’d like to know more, or exercise your right to
                        opt out in regard to all the sales carried out by Pago
                        Capital, both online and offline, you can contact us for
                        further information using the contact details provided
                        in this document.
                      </p>{" "}
                      <h4>
                        What are the purposes for which we use your personal
                        information?
                      </h4>{" "}
                      <p>
                        We may use your personal information to allow the
                        operational functioning of Pago Capital and features
                        thereof (“business purposes”). In such cases, your
                        personal information will be processed in a fashion
                        necessary and proportionate to the business purpose for
                        which it was collected, and strictly within the limits
                        of compatible operational purposes.
                      </p>{" "}
                      <p>
                        We may also use your personal information for other
                        reasons such as for commercial purposes (as indicated
                        within the section “Detailed information on the
                        processing of Personal Data” within this document), as
                        well as for complying with the law and defending our
                        rights before the competent authorities where our rights
                        and interests are threatened or we suffer an actual
                        damage.
                      </p>{" "}
                      <p>
                        We will not use your personal information for different,
                        unrelated, or incompatible purposes without notifying
                        you.
                      </p>{" "}
                      <h3>
                        Your California privacy rights and how to exercise them
                      </h3>{" "}
                      <h4>The right to know and to portability</h4>{" "}
                      <p>
                        You have the right to request that we disclose to you:
                      </p>{" "}
                      <ul>
                        {" "}
                        <li>
                          the categories and sources of the personal information
                          that we collect about you, the purposes for which we
                          use your information and with whom such information is
                          shared;
                        </li>{" "}
                        <li>
                          {" "}
                          in case of sale of personal information or disclosure
                          for a business purpose, two separate lists where we
                          disclose:{" "}
                          <ul>
                            {" "}
                            <li>
                              for sales, the personal information categories
                              purchased by each category of recipient; and{" "}
                            </li>{" "}
                            <li>
                              for disclosures for a business purpose, the
                              personal information categories obtained by each
                              category of recipient.
                            </li>{" "}
                          </ul>{" "}
                        </li>{" "}
                      </ul>{" "}
                      <p>
                        The disclosure described above will be limited to the
                        personal information collected or used over the past 12
                        months.
                      </p>{" "}
                      <p>
                        If we deliver our response electronically, the
                        information enclosed will be "portable", i.e. delivered
                        in an easily usable format to enable you to transmit the
                        information to another entity without hindrance –
                        provided that this is technically feasible.
                      </p>{" "}
                      <h4>
                        The right to request the deletion of your personal
                        information
                      </h4>{" "}
                      <p>
                        You have the right to request that we delete any of your
                        personal information, subject to exceptions set forth by
                        the law (such as, including but not limited to, where
                        the information is used to identify and repair errors on
                        Pago Capital, to detect security incidents and protect
                        against fraudulent or illegal activities, to exercise
                        certain rights etc.).
                      </p>{" "}
                      <p>
                        If no legal exception applies, as a result of exercising
                        your right, we will delete your personal information and
                        direct any of our service providers to do so.
                      </p>{" "}
                      <h4>How to exercise your rights</h4>{" "}
                      <p>
                        To exercise the rights described above, you need to
                        submit your verifiable request to us by contacting us
                        via the details provided in this document.
                      </p>{" "}
                      <p>
                        For us to respond to your request, it’s necessary that
                        we know who you are. Therefore, you can only exercise
                        the above rights by making a verifiable request which
                        must:
                      </p>{" "}
                      <ul>
                        {" "}
                        <li>
                          provide sufficient information that allows us to
                          reasonably verify you are the person about whom we
                          collected personal information or an authorized
                          representative;
                        </li>{" "}
                        <li>
                          describe your request with sufficient detail that
                          allows us to properly understand, evaluate, and
                          respond to it.
                        </li>{" "}
                      </ul>{" "}
                      <p>
                        We will not respond to any request if we are unable to
                        verify your identity and therefore confirm the personal
                        information in our possession actually relates to you.
                      </p>{" "}
                      <p>
                        If you cannot personally submit a verifiable request,
                        you can authorize a person registered with the
                        California Secretary of State to act on your behalf.
                      </p>{" "}
                      <p>
                        If you are an adult, you can make a verifiable request
                        on behalf of a minor under your parental authority.
                      </p>{" "}
                      <p>
                        You can submit a maximum number of 2 requests over a
                        period of 12 months.
                      </p>{" "}
                      <h4>
                        How and when we are expected to handle your request
                      </h4>{" "}
                      <p>
                        We will confirm receipt of your verifiable request
                        within 10 days and provide information about how we will
                        process your request.
                      </p>{" "}
                      <p>
                        We will respond to your request within 45 days of its
                        receipt. Should we need more time, we will explain to
                        you the reasons why, and how much more time we need. In
                        this regard, please note that we may take up to 90 days
                        to fulfill your request.
                      </p>{" "}
                      <p>
                        Our disclosure(s) will cover the preceding 12 month
                        period.
                      </p>{" "}
                      <p>
                        Should we deny your request, we will explain you the
                        reasons behind our denial.
                      </p>{" "}
                      <p>
                        We do not charge a fee to process or respond to your
                        verifiable request unless such request is manifestly
                        unfounded or excessive. In such cases, we may charge a
                        reasonable fee, or refuse to act on the request. In
                        either case, we will communicate our choices and explain
                        the reasons behind it.
                      </p>{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <h2 id="brazil_info">
                        Information for Users residing in Brazil
                      </h2>{" "}
                      <p>
                        This part of the document integrates with and
                        supplements the information contained in the rest of the
                        privacy policy and is provided by the entity running
                        Pago Capital and, if the case may be, its parent,
                        subsidiaries and affiliates (for the purposes of this
                        section referred to collectively as “we”, “us”, “our”).
                        <br /> The provisions contained in this section apply to
                        all Users who reside in Brazil, according to the "Lei
                        Geral de Proteção de Dados" (Users are referred to
                        below, simply as “you”, “your”, “yours”). For such
                        Users, these provisions supersede any other possibly
                        divergent or conflicting provisions contained in the
                        privacy policy. <br /> This part of the document uses
                        the term “personal information“ as it is defined in the
                        Lei Geral de Proteção de Dados (<strong>LGPD</strong>).
                      </p>{" "}
                      <h3>
                        The grounds on which we process your personal
                        information
                      </h3>{" "}
                      <p>
                        We can process your personal information solely if we
                        have a legal basis for such processing. Legal bases are
                        as follows:{" "}
                      </p>
                      <ul>
                        {" "}
                        <li>
                          {" "}
                          your consent to the relevant processing activities;
                        </li>{" "}
                        <li>
                          {" "}
                          compliance with a legal or regulatory obligation that
                          lies with us;
                        </li>{" "}
                        <li>
                          {" "}
                          the carrying out of public policies provided in laws
                          or regulations or based on contracts, agreements and
                          similar legal instruments;
                        </li>{" "}
                        <li>
                          {" "}
                          studies conducted by research entities, preferably
                          carried out on anonymized personal information;
                        </li>{" "}
                        <li>
                          {" "}
                          the carrying out of a contract and its preliminary
                          procedures, in cases where you are a party to said
                          contract;
                        </li>{" "}
                        <li>
                          {" "}
                          the exercising of our rights in judicial,
                          administrative or arbitration procedures;{" "}
                        </li>{" "}
                        <li>
                          {" "}
                          protection or physical safety of yourself or a third
                          party;
                        </li>{" "}
                        <li>
                          {" "}
                          the protection of health – in procedures carried out
                          by health entities or professionals;
                        </li>{" "}
                        <li>
                          {" "}
                          our legitimate interests, provided that your
                          fundamental rights and liberties do not prevail over
                          such interests; and{" "}
                        </li>{" "}
                        <li> credit protection.</li>{" "}
                      </ul>
                      <p />{" "}
                      <p>
                        To find out more about the legal bases, you can contact
                        us at any time using the contact details provided in
                        this document.
                      </p>{" "}
                      <h3>Categories of personal information processed</h3>{" "}
                      <p>
                        To find out what categories of your personal information
                        are processed, you can read the section titled “Detailed
                        information on the processing of Personal Data” within
                        this document.
                      </p>{" "}
                      <h3>Why we process your personal information</h3>{" "}
                      <p>
                        To find out why we process your personal information,
                        you can read the sections titled “Detailed information
                        on the processing of Personal Data” and “The purposes of
                        processing” within this document.
                      </p>{" "}
                      <h3>
                        Your Brazilian privacy rights, how to file a request and
                        our response to your requests
                      </h3>{" "}
                      <h4>Your Brazilian privacy rights</h4>{" "}
                      <p> You have the right to: </p>
                      <ul>
                        {" "}
                        <li>
                          obtain confirmation of the existence of processing
                          activities on your personal information;
                        </li>{" "}
                        <li>access to your personal information;</li>{" "}
                        <li>
                          have incomplete, inaccurate or outdated personal
                          information rectified;
                        </li>{" "}
                        <li>
                          obtain the anonymization, blocking or elimination of
                          your unnecessary or excessive personal information, or
                          of information that is not being processed in
                          compliance with the LGPD;
                        </li>{" "}
                        <li>
                          obtain information on the possibility to provide or
                          deny your consent and the consequences thereof;
                        </li>{" "}
                        <li>
                          obtain information about the third parties with whom
                          we share your personal information;
                        </li>{" "}
                        <li>
                          obtain, upon your express request, the portability of
                          your personal information (except for anonymized
                          information) to another service or product provider,
                          provided that our commercial and industrial secrets
                          are safeguarded;
                        </li>{" "}
                        <li>
                          obtain the deletion of your personal information being
                          processed if the processing was based upon your
                          consent, unless one or more exceptions provided for in
                          art. 16 of the LGPD apply;
                        </li>{" "}
                        <li>revoke your consent at any time;</li>{" "}
                        <li>
                          lodge a complaint related to your personal information
                          with the ANPD (the National Data Protection Authority)
                          or with consumer protection bodies;
                        </li>{" "}
                        <li>
                          oppose a processing activity in cases where the
                          processing is not carried out in compliance with the
                          provisions of the law;
                        </li>{" "}
                        <li>
                          request clear and adequate information regarding the
                          criteria and procedures used for an automated
                          decision; and
                        </li>{" "}
                        <li>
                          request the review of decisions made solely on the
                          basis of the automated processing of your personal
                          information, which affect your interests. These
                          include decisions to define your personal,
                          professional, consumer and credit profile, or aspects
                          of your personality.
                        </li>{" "}
                      </ul>
                      <p />{" "}
                      <p>
                        {" "}
                        You will never be discriminated against, or otherwise
                        suffer any sort of detriment, if you exercise your
                        rights.
                      </p>{" "}
                      <h4>How to file your request</h4>{" "}
                      <p>
                        You can file your express request to exercise your
                        rights free from any charge, at any time, by using the
                        contact details provided in this document, or via your
                        legal representative.
                      </p>{" "}
                      <h4>How and when we will respond to your request</h4>{" "}
                      <p>
                        We will strive to promptly respond to your requests.
                        <br /> In any case, should it be impossible for us to do
                        so, we’ll make sure to communicate to you the factual or
                        legal reasons that prevent us from immediately, or
                        otherwise ever, complying with your requests. In cases
                        where we are not processing your personal information,
                        we will indicate to you the physical or legal person to
                        whom you should address your requests, if we are in the
                        position to do so.
                      </p>{" "}
                      <p>
                        In the event that you file an <strong>access</strong> or
                        personal information{" "}
                        <strong> processing confirmation</strong> request,
                        please make sure that you specify whether you’d like
                        your personal information to be delivered in electronic
                        or printed form. <br /> You will also need to let us
                        know whether you want us to answer your request
                        immediately, in which case we will answer in a
                        simplified fashion, or if you need a complete disclosure
                        instead. <br /> In the latter case, we’ll respond within
                        15 days from the time of your request, providing you
                        with all the information on the origin of your personal
                        information, confirmation on whether or not records
                        exist, any criteria used for the processing and the
                        purposes of the processing, while safeguarding our
                        commercial and industrial secrets.
                      </p>{" "}
                      <p>
                        In the event that you file a{" "}
                        <strong>
                          rectification, deletion, anonymization or personal
                          information blocking
                        </strong>{" "}
                        request, we will make sure to immediately communicate
                        your request to other parties with whom we have shared
                        your personal information in order to enable such third
                        parties to also comply with your request – except in
                        cases where such communication is proven impossible or
                        involves disproportionate effort on our side.
                      </p>{" "}
                      <h3>
                        Transfer of personal information outside of Brazil
                        permitted by the law
                      </h3>{" "}
                      <p>
                        We are allowed to transfer your personal information
                        outside of the Brazilian territory in the following
                        cases:{" "}
                      </p>
                      <ul>
                        {" "}
                        <li>
                          when the transfer is necessary for international legal
                          cooperation between public intelligence, investigation
                          and prosecution bodies, according to the legal means
                          provided by the international law;
                        </li>{" "}
                        <li>
                          when the transfer is necessary to protect your life or
                          physical security or those of a third party;
                        </li>{" "}
                        <li>when the transfer is authorized by the ANPD;</li>{" "}
                        <li>
                          when the transfer results from a commitment undertaken
                          in an international cooperation agreement;
                        </li>{" "}
                        <li>
                          when the transfer is necessary for the execution of a
                          public policy or legal attribution of public service;
                        </li>{" "}
                        <li>
                          when the transfer is necessary for compliance with a
                          legal or regulatory obligation, the carrying out of a
                          contract or preliminary procedures related to a
                          contract, or the regular exercise of rights in
                          judicial, administrative or arbitration procedures.
                        </li>{" "}
                      </ul>
                      <p />{" "}
                    </div>{" "}
                    <div className="one_line_col">
                      {" "}
                      <div className="box_primary box_10 definitions expand">
                        {" "}
                        <h3
                          id="definitions_and_legal_references"
                          className="expand-click w_icon_24 icon_ribbon"
                        >
                          {" "}
                          Definitions and legal references{" "}
                        </h3>{" "}
                        <div className="expand-content">
                          {" "}
                          <h4>Personal Data (or Data)</h4>{" "}
                          <p>
                            Any information that directly, indirectly, or in
                            connection with other information — including a
                            personal identification number — allows for the
                            identification or identifiability of a natural
                            person.
                          </p>{" "}
                          <h4>Usage Data</h4>{" "}
                          <p>
                            Information collected automatically through Pago
                            Capital (or third-party services employed in Pago
                            Capital), which can include: the IP addresses or
                            domain names of the computers utilized by the Users
                            who use Pago Capital, the URI addresses (Uniform
                            Resource Identifier), the time of the request, the
                            method utilized to submit the request to the server,
                            the size of the file received in response, the
                            numerical code indicating the status of the server's
                            answer (successful outcome, error, etc.), the
                            country of origin, the features of the browser and
                            the operating system utilized by the User, the
                            various time details per visit (e.g., the time spent
                            on each page within the Application) and the details
                            about the path followed within the Application with
                            special reference to the sequence of pages visited,
                            and other parameters about the device operating
                            system and/or the User's IT environment.
                          </p>{" "}
                          <h4>User</h4>{" "}
                          <p>
                            The individual using Pago Capital who, unless
                            otherwise specified, coincides with the Data
                            Subject.
                          </p>{" "}
                          <h4>Data Subject</h4>{" "}
                          <p>
                            The natural person to whom the Personal Data refers.
                          </p>{" "}
                          <h4>Data Processor (or Data Supervisor)</h4>{" "}
                          <p>
                            The natural or legal person, public authority,
                            agency or other body which processes Personal Data
                            on behalf of the Controller, as described in this
                            privacy policy.
                          </p>{" "}
                          <h4>Data Controller (or Owner)</h4>{" "}
                          <p>
                            The natural or legal person, public authority,
                            agency or other body which, alone or jointly with
                            others, determines the purposes and means of the
                            processing of Personal Data, including the security
                            measures concerning the operation and use of Pago
                            Capital. The Data Controller, unless otherwise
                            specified, is the Owner of Pago Capital.
                          </p>{" "}
                          <h4>Pago Capital (or this Application)</h4>{" "}
                          <p>
                            The means by which the Personal Data of the User is
                            collected and processed.
                          </p>{" "}
                          <h4>Service</h4>{" "}
                          <p>
                            The service provided by Pago Capital as described in
                            the relative terms (if available) and on this
                            site/application.
                          </p>{" "}
                          <h4>European Union (or EU)</h4>{" "}
                          <p>
                            Unless otherwise specified, all references made
                            within this document to the European Union include
                            all current member states to the European Union and
                            the European Economic Area.
                          </p>{" "}
                          <h4>Cookie</h4>{" "}
                          <p>
                            Cookies are Trackers consisting of small sets of
                            data stored in the User's browser.
                          </p>{" "}
                          <h4>Tracker</h4>{" "}
                          <p>
                            Tracker indicates any technology - e.g Cookies,
                            unique identifiers, web beacons, embedded scripts,
                            e-tags and fingerprinting - that enables the
                            tracking of Users, for example by accessing or
                            storing information on the User’s device.
                          </p>{" "}
                          <hr /> <h4>Legal information</h4>{" "}
                          <p>
                            This privacy statement has been prepared based on
                            provisions of multiple legislations, including Art.
                            13/14 of Regulation (EU) 2016/679 (General Data
                            Protection Regulation).
                          </p>{" "}
                          <p>
                            This privacy policy relates solely to Pago Capital,
                            if not stated otherwise within this document.
                          </p>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="iub_footer">
                      {" "}
                      <p> Latest update: April 30, 2022 </p>{" "}
                      <p>
                        {" "}
                        <a
                          target="_top"
                          href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                          title="iubenda - Privacy Policy generator"
                        >
                          iubenda
                        </a>{" "}
                        hosts this content and only collects{" "}
                        <a
                          target="_top"
                          href="//www.iubenda.com/privacy-policy/65675001"
                        >
                          the Personal Data strictly necessary
                        </a>{" "}
                        for it to be provided.{" "}
                      </p>{" "}
                    </div>{" "}
                    {/* /footer */}
                  </div>{" "}
                  {/* /content */}{" "}
                </div>{" "}
                {/* /wbars */}{" "}
              </div>{" "}
              {/* /container base_container */}{" "}
            </div>{" "}
            {/* /wbars_wrapper */}{" "}
          </div>{" "}
          {/* /iubenda_policy */}{" "}
        </div>
      </div>
    </div>
  </section>
</div>

    </>
  );
};

export default PrivacyPolicy;





// function PrivacyPolicy(){
//   (function (w,d) {
//     var loader = function () {
//       var s = d.createElement("script"), 
//       tag = d.getElementsByTagName("script")[0]; 
//   s.src="https://cdn.iubenda.com/iubenda.js"; 
//   tag.parentNode.insertBefore(s,tag);
// }; 
//   if(w.addEventListener){
//     w.addEventListener("load", loader, false);
//   }
//   else if(w.attachEvent){
//     w.attachEvent("onload", loader);
//   }
//   else{w.onload = loader;
//   }
// }
// )(window, document);
//     return(<>
//         <title>Privacy Policy</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, shrink-to-fit=no"
//         />
//         <meta name="description" content="" />
//         <meta name="author" content="" />
//         <link rel="preconnect" href="https://fonts.gstatic.com" />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
//         />
//         <link
//           rel="stylesheet"
//           href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
//         />
//         {/* <link rel="stylesheet" href="css/tailwind/tailwind.min.css" />
//         <link rel="stylesheet" href="css/main.css" /> */}
//         <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" />
        
//         <div className="">
//           <section className="font-medium border-2 border-purple-300">
//             <div className="container mx-auto">
//               <h2 className="py-10 leading-tight font-heading text-9xl md:text-10xl">
//                 Privacy Policy
//               </h2>
//               <body>
//                 <div className="flex mx-auto max-w-max">
//                   <a href="https://www.iubenda.com/privacy-policy/62836498" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iub-legal-only iub-body-embed" title="Privacy Policy">Privacy Policy</a>
//                 </div>
//               </body>
//             </div>
//           </section>
//         </div>
//       </>
//       );
// };

// export default PrivacyPolicy;