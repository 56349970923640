import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import React, {useEffect } from 'react';
import ReactDOM from 'react-dom';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import DownloadWallet from './pages/DownloadWallet';
import RemittancePayments from './pages/use-cases/RemittancePayments';
import PointOfSalePayments from './pages/use-cases/PointOfSalePayments';
import VoiceWallet from './pages/demos/VoiceWallet';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
// import Blog from './pages/Blog';
// import CaseStudies from './pages/CaseStudies';

import Footer from './components/Footer';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import Error from './pages/Error';
import UserGuide from './pages/UserGuide';

import favicon from './assets/images/pago-favicon.png';


const TRACKING_ID = "UA-206868509-1"; //TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {

useEffect(() => {
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);

  return(
    <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/download-wallet" element={<DownloadWallet />} />
          <Route path="/remittance-payments" element={<RemittancePayments />} />
          <Route path="/point-of-sale-payments" element={<PointOfSalePayments />} />
          <Route path="/voice-wallet" element={<VoiceWallet />} />
          <Route path="/user-guide" element={<UserGuide />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/case-studies" element={<CaseStudies />} /> */}
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="*" element={<Error/>} />
        </Routes>
        <Footer />
      </Router>
  );
};

export default App;