import qr from '../assets/images/qr.png';
import lineblue1 from '../assets/images/line-blue1.svg';
import payment from '../assets/images/payment.png';
import lineblue3 from '../assets/images/line-blue3.svg';
import lineblue2 from '../assets/images/line-blue2.svg';
import appstore from '../assets/images/appstore.svg';
import google from '../assets/images/google.svg';
import pagowalletapp from '../assets/images/pago-wallet-app.png';
import simplewallet from '../assets/images/simple-wallet.png';
import secure from '../assets/images/secure.png';
import scalable from '../assets/images/scalable.png';
import lowcost from '../assets/images/low-cost.png';
import fast from '../assets/images/fast.png';
import accessible from '../assets/images/accessible.png';
import security from '../assets/images/security.png';

import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';

function DownloadWallet(){

  const gaEventTracker = useAnalyticsEventTracker('Download Wallet');

    return(<>
        <title>Download Pago Wallet</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />

        {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
        <div className="">
          <div>
            <section className="flex flex-wrap py-8 overflow-hidden border-2 border-purple-300 bg-purple-50">
              <div className="container relative px-4 mx-auto">
                <div className="flex flex-wrap items-center h-full -mx-4">
                  <div className="w-full h-full px-4 mb-20 md:w-1/2 md:mb-0">
                    <span className="text-xs font-medium tracking-widest text-gray-300 uppercase lg:text-2xl">
                      borderless digital wallet for the next generation
                    </span>
                    <h2 className="mt-2 text-5xl leading-tight font-heading lg:text-10xl md:text-7xl">
                      A blockchain wallet built for <b>everyone</b>
                    </h2>
                    <p className="mt-5 mb-5 text-darkBlueGray-400 lg:text-4xl">
                      Built on a proprietary Transaction Gateway and powered by the
                      Algorand Blockchain, Pago makes digital transactions <b>cheap</b>,
                      <b>secure</b>, and <b>unbelievably simple</b>.
                    </p>
                    <p className="mb-8 text-darkBlueGray-400 lg:text-4xl">
                      Download the Pago Wallet on your <b>iOS</b> or <b>Android</b>
                      device, and join the global payment revolution.
                    </p>
                    <div className="lg:flex">
                      <a
                        className="inline-block mr-4"
                        href="https://apps.apple.com/ro/app/pago-wallet/id1581076779?platform=iphone"
                        target="_blank" rel="noopener noreferrer" onClick={()=>gaEventTracker('wallet-apple')}
                      >
                        <img
                          src={appstore}
                          alt="Pago Digital Blockchain Wallet Mobile Application for iOS"
                          className=""
                        />
                      </a>
                      <a
                        className="inline-block"
                        href="https://play.google.com/store/apps/details?id=com.pago.wallet.androidApp&hl=en&gl=US"
                        target="_blank" rel="noopener noreferrer" onClick={()=>gaEventTracker('wallet-google')}
                      >
                        <img
                          src={google}
                          alt="Pago Digital Blockchain Wallet Mobile Application for Android"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="relative h-full md:w-1/2">
                    <div className="absolute bottom-0 left-0 hidden w-full h-full bg-blueGray-100 rounded-b-10xl" />
                    <img
                      className="z-10 w-full h-full"
                      src={pagowalletapp}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-white">
              <div className="container px-6 mx-auto">
                <div className="text-center">
                  <h2 className="pt-10 leading-none text-purple-300 text-10xl mb-7">
                    How It Works
                  </h2>
                  <p className="mb-5 text-xl leading-relaxed opacity-50 2xl:pr-36 lg:text-2xl font-heading">
                    A lightweight mobile app for borderless, secure, and real-time
                    digital payments, powered by the Algorand blockchain.
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-center -mx-4 md:flex-nowrap">
                  <div className="w-full px-4 md:w-11/12 md:mb-0">
                    <div className="flex flex-wrap">
                      <div className="w-full px-4 md:w-1/2 xl:w-1/3">
                        <div className="relative max-w-sm mx-auto mb-8">
                          <div className="z-10 px-8 pt-16 pb-20 border border-black md:px-16 border-opacity-10 rounded-3xl bg-purple-50 hover:bg-white">
                            <div className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                              <span className="text-2xl font-bold">1</span>
                            </div>
                            <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                              Download the app
                            </h2>
                            <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                              The mobile app is available for iOS and Android devices.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2 xl:w-1/3">
                        <div className="relative max-w-sm mx-auto mb-8 xl:mt-10">
                          <div className="z-10 px-8 pt-16 pb-20 border border-black md:px-16 border-opacity-10 rounded-3xl bg-purple-50 hover:bg-white">
                            <div className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                              <span className="text-2xl font-bold">2</span>
                            </div>
                            <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                              Create an account
                            </h2>
                            <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                              Create a paystring unique to you. No more credit card
                              numbers. No more lengthy address strings. Claim{" "}
                              <b>yourName$pago.capital</b> today.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2 xl:w-1/3">
                        <div className="relative max-w-sm mx-auto mb-8">
                          <div className="z-10 px-8 pt-16 pb-20 border border-black md:px-16 border-opacity-10 rounded-3xl bg-purple-50 hover:bg-white">
                            <div className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                              <span className="text-2xl font-bold">3</span>
                            </div>
                            <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                              Sign transactions
                            </h2>
                            <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                              Send and receive funds globally. Buy anything, anywhere.
                              Pago supports <b>online</b> and
                              <b>physical point-of-sale</b> transactions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="justify-center pt-7 bg-purple-50">
              <div className="container justify-center px-4 mx-auto">
                <div className="flex flex-wrap justify-evenly">
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3 lg:mb-0">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-20 mx-auto opacity-70"
                        src={simplewallet}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Simple
                        </h3>
                        <p>
                          Based on a public blockchain, built to be borderless with a
                          decentralized trust model
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3 md:mb-0">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-20 mx-auto opacity-80"
                        src={scalable}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Scalable
                        </h3>
                        <p>
                          Easily connect with existing payment ecosystems or develop new
                          ones
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-20 mx-auto opacity-80"
                        src={secure}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Secure
                        </h3>
                        <p>
                          Security at the consensus protocol, network, and user levels
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="justify-center py-5 pt-7 bg-purple-50">
              <div className="container justify-center px-4 mx-auto">
                <div className="flex flex-wrap justify-evenly">
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3 lg:mb-0">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-16 mx-auto mb-10"
                        src={lowcost}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Low-Cost
                        </h3>
                        <p>Costs less than 1 cent (USD) per transaction</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3 md:mb-0">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-16 mx-auto mb-10"
                        src={fast}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Lightning-Fast
                        </h3>
                        <p>
                          High speed and low latency, with transactions committed in
                          under 5 seconds
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 md:w-1/4 lg:w-1/3">
                    <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                      <img
                        className="block h-16 mx-auto mb-10"
                        src={accessible}
                        alt=""
                      />
                      <div className="max-w-xs px-6 mx-auto lg:px-0">
                        <h3 className="mb-2 text-lg font-medium font-heading">
                          Adaptable
                        </h3>
                        <p>
                          Extensive support for businesses, payment processors, and
                          developers to pioneer novel payment ecosystems
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="py-5 border-2 border-purple-300 bg-orange-50 bg-opacity-40">
          <div className="container px-4 mx-auto">
            <div className="mx-auto text-center">
              <h2 className="w-full mb-2 text-purple-300 text-9xl">
                More than just a payment app.
              </h2>
              <p className="w-full mb-1 text-xl text-gray-400">
                Blockchain payment systems should be more streamlined and
                accessible, particularly in emerging markets.
              </p>
              <p className="w-full mb-10 text-xl text-gray-400">
                Pago enables decentralized access to novel and existing payment
                networks around the world with:
              </p>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4 mb-10 lg:w-1/3 lg:mb-0">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl" src={qr} alt="" />
                </div>
                <div className="relative w-4/5 sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 right-0 -mt-40 -mr-12"
                    src={lineblue1} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Rapid Point-of-Sale Integration
                    </h3>
                    <p className="text-gray-400">
                      Pago enables physical and online businesses to quickly and
                      securely accept payments at the point-of-sale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 mb-10 lg:w-1/3 lg:mb-0 lg:mt-24">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl" src={payment}
                    alt="" />
                </div>
                <div className="relative w-4/5 ml-auto sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 bottom-0 mb-20 -mt-20 -ml-12"
                    src={lineblue3} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Simple Peer-to-Peer Payments
                    </h3>
                    <p className="text-gray-400">
                      With virtually negligible transaction fees, the Pago Wallet
                      can be used to quickly and efficiently send and receive money.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/3">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl"
                    src={security} alt="" />
                </div>
                <div className="relative w-4/5 ml-auto sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 left-0 -mt-24 -ml-12"
                    src={lineblue2} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Unparalleled Security
                    </h3>
                    <p className="text-gray-400">
                      Security is a top priority for Pago. Two-factor authentication
                      is provided for all signing wallets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          </div>
        </div>
      </>
      );
};

export default DownloadWallet;