import lowpolygrid from '../assets/images/low-poly-grid-md.svg';
import qr from '../assets/images/qr.png';
import lineblue1 from '../assets/images/line-blue1.svg';
import payment from '../assets/images/payment.png';
import lineblue3 from '../assets/images/line-blue3.svg';
import security from '../assets/images/security.png';
import lineblue2 from '../assets/images/line-blue2.svg';
import simplewallet from '../assets/images/simple-wallet.png';
import secure from '../assets/images/secure.png';
import scalable from '../assets/images/scalable.png';
import lowcost from '../assets/images/low-cost.png';
import fast from '../assets/images/fast.png';
import accessible from '../assets/images/accessible.png';

import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';

function Home() {
  const gaEventTracker = useAnalyticsEventTracker('Pago Home');

// Plug into Pago to tap into the blockchain
  
  return (<>
    <title>Pago Capital</title>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" />

    {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
    <div className="">
      <div>
        <section className="flex flex-wrap overflow-hidden border-2 border-purple-300">
          <div className="flex flex-wrap w-full mx-auto bg-purple-50">
            <div className="container relative mx-auto">
              <div className="flex flex-wrap items-center">
                <div className="pl-10 lg:w-2/3">
                  <span className="block mt-5 text-lg font-medium tracking-widest text-gray-300 uppercase">
                    Augmenting payment systems with cutting-edge blockchain technology
                    
                  </span>
                  <h1 className="py-5 pr-20 font-medium leading-none font-heading text-9xl md:text-10xl">
                    <span className="font-heading">
                      Enabling Next-Generation Crypto Payments
                    </span>
                  </h1>
                  <p className="py-5 pr-40 text-xl leading-snug text-darkBlueGray-400">
                    A lightweight mobile app for <b>borderless</b>, <b>secure</b>,
                    and <b>real-time</b> digital payments.
                  </p>
                  <a href="/download-wallet" target="_blank"
                    className="inline-block px-10 py-5 mb-10 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600"
                    onClick={()=>gaEventTracker('download-wallet')}>
                    Get Pago
                  </a>
                </div>
                <div className="hidden lg:block lg:w-1/3 opacity-30">
                  <div className="relative mx-auto">
                    <img className="relative z-10 opacity-90" src={lowpolygrid} alt="Pago Capital" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 border-2 border-purple-300 bg-orange-50 bg-opacity-40">
          <div className="container px-4 mx-auto">
            <div className="mx-auto text-center">
              <h2 className="w-full mb-2 text-purple-300 text-9xl">
                More than just a payment app.
              </h2>
              <p className="w-full mb-1 text-xl text-gray-400">
                Blockchain payment systems should be more streamlined and
                accessible, particularly in emerging markets.
              </p>
              <p className="w-full mb-10 text-xl text-gray-400">
                Pago enables decentralized access to novel and existing payment
                networks around the world with:
              </p>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full px-4 mb-10 lg:w-1/3 lg:mb-0">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl" src={qr} alt="" />
                </div>
                <div className="relative w-4/5 sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 right-0 -mt-40 -mr-12"
                    src={lineblue1} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Rapid Point-of-Sale Integration
                    </h3>
                    <p className="text-gray-400">
                      Pago enables physical and online businesses to quickly and
                      securely accept payments at the point-of-sale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 mb-10 lg:w-1/3 lg:mb-0 lg:mt-24">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl" src={payment}
                    alt="" />
                </div>
                <div className="relative w-4/5 ml-auto sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 bottom-0 mb-20 -mt-20 -ml-12"
                    src={lineblue3} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Simple Peer-to-Peer Payments
                    </h3>
                    <p className="text-gray-400">
                      With virtually negligible transaction fees, the Pago Wallet
                      can be used to quickly and efficiently send and receive money.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/3">
                <div className="mb-6">
                  <img className="object-contain w-full py-2 bg-purple-900 h-60 rounded-5xl"
                    src={security} alt="" />
                </div>
                <div className="relative w-4/5 ml-auto sm:w-1/2 lg:w-3/4">
                  <img className="absolute top-0 left-0 -mt-24 -ml-12"
                    src={lineblue2} alt="" />
                  <div className="relative p-10 px-4 border-2 border-blue-200 md:px-8 xl:px-20 rounded-5xl bg-purple-50">
                    <h3 className="mb-2 text-xl font-medium leading-loose font-heading">
                      Unparalleled Security
                    </h3>
                    <p className="text-gray-400">
                      Security is a top priority for Pago. Two-factor authentication
                      is provided for all signing wallets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="justify-center pt-7 bg-purple-50">
          <div className="container justify-center px-4 mx-auto">
            <div className="flex flex-wrap justify-evenly">
              <div className="w-full px-4 md:w-1/4 lg:w-1/3 lg:mb-0">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-70" src={simplewallet} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Simple
                    </h3>
                    <p>
                      Based on a public blockchain, built to be borderless with a
                      decentralized trust model
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/4 lg:w-1/3 md:mb-0">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-80" src={scalable} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Scalable
                    </h3>
                    <p>
                      Easily connect with existing payment ecosystems or develop new
                      ones
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/4 lg:w-1/3">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-80" src={secure} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Secure
                    </h3>
                    <p>
                      Security at the consensus protocol, network, and user levels
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="justify-center py-5 pt-7 bg-purple-50">
          <div className="container justify-center px-4 mx-auto">
            <div className="flex flex-wrap justify-evenly">
              <div className="w-full px-4 md:w-1/4 lg:w-1/3 lg:mb-0">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-75" src={lowcost} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Low-Cost
                    </h3>
                    <p>Costs less than 1 cent (USD) per transaction</p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/4 lg:w-1/3 md:mb-0">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-95" src={fast} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Lightning-Fast
                    </h3>
                    <p>
                      High speed and low latency, with transactions committed in
                      under 5 seconds
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/4 lg:w-1/3">
                <div className="p-3 text-center rounded-5xl bg-purple-50 hover:bg-white">
                  <img className="block h-20 mx-auto opacity-90" src={accessible} alt="" />
                  <div className="max-w-xs px-6 mx-auto lg:px-0">
                    <h3 className="mb-2 text-lg font-medium font-heading">
                      Adaptable
                    </h3>
                    <p>
                      Extensive support for businesses, payment processors, and
                      developers to pioneer novel payment ecosystems
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="px-5 py-10 bg-center bg-no-repeat border-2 border-purple-300">
          <section className="lg:flex lg:items-center lg:pt-0 justify-items-center">
            <div className="container justify-between px-8 py-2 mx-auto lg:w-1/2 mb-5lg:flex lg:mb-0">
              <h2 className="leading-none text-purple-300 text-10xl mb-7">
                Digital payments made easy
              </h2>
              <p className="text-xl leading-relaxed opacity-50 2xl:pr-36 lg:text-2xl font-heading mb-7">
                A lightweight mobile app for borderless, secure, and real-time
                digital payments, powered by the Algorand blockchain.
              </p>
              <div className="mb-3 font-heading">
                <a href="/blog" target="_blank">
                  <button
                    className="w-full px-10 py-5 mb-4 mr-0 text-xl font-medium leading-none tracking-tighter md:mb-0 md:mr-5 md:w-auto font-heading bg-gray-50 hover:bg-gray-100 focus:ring-2 focus:ring-gray-50 focus:ring-opacity-50 rounded-xl"
                    type="button">
                    Read the Blog
                  </button>
                </a>
                <a href="/download-wallet" target="_blank">
                  <button
                    className="w-full px-10 py-5 text-xl font-medium leading-none tracking-tighter text-white bg-purple-400 md:w-auto font-heading focus:ring-2 focus:ring-opacity-50 rounded-xl focus:ring-purple-500 hover:bg-purple-600"
                    onClick={()=>gaEventTracker('download-app-link')}
                    type="button">
                    Get the App
                  </button>
                </a>
              </div>
              <p className="text-lg font-normal leading-6 text-darkBlueGray-400">
                Explore the Pago ecosystem.
              </p>
            </div>
            <div className="px-4 lg:px-20 lg:w-1/2 lg:rounded-b-10xl">
              <div className="flex flex-col px-8 py-5 mb-2 border-2 lg:flex-row rounded-3xl hover:bg-white bg-purple-50">
                <div>
                  <div
                    className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                    <span className="text-2xl font-bold">1</span>
                  </div>
                </div>
                <div className="lg:ml-4 xl:ml-12">
                  <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                    Download the app
                  </h2>
                  <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                    The mobile app is available for iOS and Android devices.
                  </p>
                </div>
              </div>
              <div className="flex flex-col px-8 py-5 mb-2 border-2 lg:flex-row rounded-3xl hover:bg-white bg-purple-50">
                <div>
                  <div
                    className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                    <span className="text-2xl font-bold">2</span>
                  </div>
                </div>
                <div className="lg:ml-4 xl:ml-12">
                  <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                    Create an account
                  </h2>
                  <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                    Create a paystring unique to you. No more credit card numbers.
                    No more lengthy address strings. Claim{" "}
                    <b>yourName$pago.capital</b> today.
                  </p>
                </div>
              </div>
              <div className="flex flex-col px-8 py-5 border-2 lg:flex-row rounded-3xl hover:bg-white bg-purple-50">
                <div>
                  <div
                    className="relative inline-flex items-center justify-center w-12 h-12 mb-8 leading-6 text-white bg-purple-400 rounded-full">
                    <span className="text-2xl font-bold">3</span>
                  </div>
                </div>
                <div className="lg:ml-4 xl:ml-12">
                  <h2 className="mb-2 leading-tight text-7xl xl:text-8xl">
                    Sign transactions
                  </h2>
                  <p className="text-lg leading-6 lg:max-w-sm text-darkBlueGray-400">
                    Send and receive funds globally. Buy anything, anywhere. Pago
                    supports <b>online</b> and <b>physical point-of-sale</b>{" "}
                    transactions.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </>);
};

export default Home;