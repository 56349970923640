import React from 'react';
import POSimage from "../../assets/images/POS-image.png";
import Onlinepayment from "../../assets/images/Online-Payment-with-Pago-POS.png";

function PointOfSalePayments() {
    return(
    <>
        <title>Securely Powering Instant Point-of-Sale (POS) Payments</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Rubik" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap"
        />

        {/* <link rel="icon" type="image/png" sizes="16x16" href="../assets/images/pago-favicon.png" /> */}
        <div className="">
          
          <section className="overflow-hidden pt-5 pb-5 bg-purple-50">
            <div className="container mx-auto">
              <div className="mb-10 text-center">
                <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
                Securely Powering Instant Point-of-Sale (POS) Payments
                </h2>
              </div>
              <div className="max-w-6xl mx-auto">
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
  Despite their modern appearance, today’s payment systems predominantly rely on
  outdated
  <a
    href="https://www.britannica.com/topic/credit-card"
    target="_blank"
    rel="noopener noreferrer"
  >
    credit card technology
  </a>
  developed in the 1950s. As a result, the process of sending and receiving
  payments is expensive, slow, and fraught with fraud, resulting in costly and
  siloed payment ecosystems. Furthermore, centralized systems promote
  <a
    href="https://www.centerforfinancialinclusion.org/where-are-the-financially-excluded"
    target="_blank"
    rel="noopener noreferrer"
  >
    financial exclusion
  </a>
  , which is a leading contributor to poverty. In fact, over 50% of the world’s
  working adult population is considered 
  <a
    href="https://www.un.org/esa/socdev/documents/youth/fact-sheets/youth-financial-inclusion.pdf"
    target="_blank"
    rel="noopener noreferrer"
  >unbanked</a>.</p>
              </div>
              <img
                  className="object-cover object-top w-full h-2/3 md:h-full rounded-b-50 md:rounded-bl-none"
                  src={POSimage}
                  alt="Point-of-Sale Payment"
                />
            </div>
          </section>
          
          <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
            <div className="-mt-40 md:-mt-0">
              <div className="container mx-auto">
                <div className="max-w-6xl mx-auto">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                    Problems with Current Payment Systems
                  </h3>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Sending and receiving payments is currently a
                              cumbersome, insecure, and costly process. Despite
                              millions of people worldwide relying on them,
                              traditional payment systems are:
                              <ul className="text-lg font-normal leading-6 list-disc list-inside">
                              <li>
                                <strong>Fragmented: </strong>Sending payments across
                                borders is slow, expensive, and ineffcient.
                              </li>
                              <li>
                                <strong>Exclusive: </strong>Payments are not globally
                                accessible. In fact, around fifty percent of the
                                world’s adult population doesn’t have access to
                                reliable financial systems (if any). This also limits
                                the market reach that a specific business or payment
                                provider can have, particularly at the global scale.
                              </li>
                              <li>
                                <strong>Expensive: </strong>Transaction fees range
                                from 2 to 4% (+ $0.30) for credit cards and up to over
                                10% for cross-border payments.
                              </li>
                              <li>
                                <strong>Inefficient: </strong>Payment systems are
                                based on 50-year-old technology.
                              </li>
                              <li>
                                <strong>Slow: </strong>It takes two days (or longer)
                                to settle transactions.
                              </li>
                              <li>
                                <strong>Insecure: </strong>Fraud and chargebacks hurt
                                merchants and consumers.
                              </li>
                            </ul>
                  </p>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  These hurdles could be largely eliminated via
                              blockchain-based payments, but blockchain payment
                              systems first need to become more streamlined and
                              accessible, particularly in emerging markets.
                  </p>
                  <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  Pago is a decentralized payment service that connects
                              users with physical and online businesses at the point
                              of sale. It leverages Algorand’s blockchain to enable
                              users to quickly, securely, efficiently, and cheaply
                              send payments worldwide.
                  </p>
                </div>
              </div>
            </div>
            <div className="container mx-auto">
              <div className="max-w-6xl mx-auto">
              <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                Pago and the Algorand Blockchain
                  </h3>
                <p className="max-w-4xl text-xl leading-10 text-darkBlueGray-400">
                Pago is based on the highly-efficient Algorand
                              blockchain, which is a next-generation blockchain
                              founded and developed by Silvio Micali, a Turing Award
                              winning cryptographer and MIT professor. Algorand uses a
                              <a
                                href="https://www.algorand.com/resources/blog/proof-of-stake-vs-pure-proof-of-stake-consensus"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                proof-of-stake consensus protocol
                              </a>
                              , which makes it extremely efficient, fast, and secure.
                              It is also a public and open-source blockchain based on
                              a decentralized trust model that disintermediates
                              bankers and other intermediaries that charge excessive
                              transaction fees ranging from 3% to 10%.
                </p>
                <p className="max-w-4xl text-xl leading-10 text-darkBlueGray-400">
                The entire Algorand network consumes the energy
                              equivalent to power ten homes, in contrast to the
                              Bitcoin network, whose energy consumption exceeds that
                              of Switzerland. Algorand’s efficiency results in a rapid
                              transaction commit time of 4.5 seconds and a throughput
                              of over 1000 transactions per second. The efficiency
                              also results in nominal transaction fees of less than
                              $0.01 USD. The speed, scalability, and low cost provided
                              by Algorand are essential for the real-time payments
                              facilitated by the Pago Transaction Gateway.
                </p>
              </div>
            </div>
          </section>

          <section className="overflow-hidden pt-5 pb-5 bg-purple-50">
            <div className="container mx-auto">
              <div className="text-center">
              <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                How Pago Streamlines Digital Payments
                </h3>
              </div>
              <div className="max-w-6xl mx-auto">
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                Pago promises to revolutionize the payments industry with blockchain technology. 
                With a borderless, fast, low-cost, and secure payments platform, Pago aims to globally democratize access to financial services 
                by empowering anyone with a smartphone to participate. In addition to point of sale, Pago supports a wide variety of payment-related 
                use cases, including but not limited to: online payments, vending machines, public transit tokens, peer to peer payments (e.g. remittances), 
                IoT payments, and document signing.
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                Pago is an environmentally-friendly application that aims to help reduce the carbon impact of blockchain technology. It also aims to 
                democratizes access to financial services globally by making blockchain transactions as simple as sending a text message or email. 
                Furthermore, Pago is secure; for instance, wallet credentials never leave the user’s phone.
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                But how is Pago different from existing payment solutions? Ultimately, Pago aims to disrupt the global payments industry by empowering a wide 
                variety of customer segments, including businesses, payment processors, and consumers. As such, it offers the following key benefits:
                </p>
                </div>
            </div>
          </section>
          
          <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
            <div className="-mt-40 md:-mt-0">
              <div className="container mx-auto">
                <div className="max-w-6xl mx-auto">
                  <h3 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">
                    Key Benefits of Using Pago for Digital Payments
                  </h3>
                  <div>
                    <ol>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">1. Simplicity</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Pago provides a simple and easy-to-use payment API for
                              conducting transactions between businesses and
                              consumers. Our Transaction Gateway API makes integration
                              easy without the need for smart contracts.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">2. Transparency</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Pago is based on the Algorand public blockchain with a
                              public, decentralized trust model based on the core
                              principles of transparency and security. We utilize
                              ALGOs as a utility token rather than a security token.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">3. Scalability</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              The platform can scale up to 1,150 transactions per
                              second, thereby simultaneously supporting a large volume
                              of users. Developers can easily connect with existing
                              payment ecosystems or develop novel applications and use
                              cases.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">4. Security</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Security is incorporated at the consensus protocol,
                              network, and user levels. User credentials are secured
                              by the Pago Wallet as well.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">5. Low Cost</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Pago charges less than 1 cent (USD) per transaction.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">6. Lightning-Fast Speed</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Pago offers high speed and low latency, with
                              transactions committed and settled in under 5 seconds.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">7. Adaptability</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              There are limitless opportunities to pioneer novel
                              blockchain-based payment ecosystems using Pago. Pago
                              also promotes stable coins for global commerce and
                              supports the growth of Algorand’s transaction volume and
                              consumer base.
                            </p></li>
                      <li><h2 className="mb-10 font-medium leading-snug font-heading text-7xl xl:text-8xl">8. Inclusiveness</h2>
                            <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Pago aims to expand access to digital payments, savings,
                              and lending to a large portion of the world’s population
                              currently excluded by traditional payment systems. Lack
                              of access to financial services is a leading contributor
                              to poverty, and Pago seeks to enhance inclusiveness and
                              borderless access to secure, reliable payment options.
                            </p></li>
                    </ol>
                    <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                              Essentially, Pago leverages Algorand’s blockchain to
                              enable users to quickly, securely, efficiently, and
                              cheaply send payments worldwide.
                            </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-5 pt-5 overflow-hidden bg-purple-50">
            <div className="container mx-auto">
              <div className="mb-10 text-center">
                <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
                How Pago Supports Point-of-Sale (POS) Payments
                </h2>
                <img
                    className="object-cover object-top w-full h-2/3 md:h-full rounded-b-50 md:rounded-bl-none"
                    src={Onlinepayment}
                    alt=""
                  />
              </div>
              <div className="max-w-6xl mx-auto">
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                The Pago Payment Platform provides fast, efficient,
                              low-cost, and secure point-of-sale (POS) payments. Using
                              Pago is easy! Simply download the Pago Wallet to your
                              smartphone, register, and fund your account. Pago
                              integrates with the Wyre exchange service to quickly
                              transfer funds from a bank account or credit card to
                              your Pago Wallet.
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                Once you have your Pago Wallet set up, you are ready to
                              use your wallet to pay for purchases at participating
                              businesses, either point-of-sale or online. To complete
                              a payment, simply share your PayString (e.g.,
                              alice$pago.dev) with the merchant, and then review and
                              accept the transaction from your phone. Once approved,
                              the transaction is committed to the blockchain to
                              complete the purchase within 5 seconds.
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                The 
                              <a
                                href="/download-wallet"
                                target="_blank"
                                rel="noopener"
                              >
                                Pago Wallet
                              </a>
                              is an easy-to-use mobile app with support for Android
                              and iOS smartphones. Using the Pago Wallet, users can
                              check balances, request payments, send payments, and
                              approve payment requests from other users or businesses.
                              The Pago Wallet supports, peer-to-peer, point-of-sale
                              and online payments. The Wallet also provides
                              transaction details like receipts for retail payments,
                              for instance.
                </p>
            </div>
          </div>
        </section>
        <section className="pt-5 pb-5 overflow-hidden bg-purple-100">
            <div className="container mx-auto">
              <div className="mb-10 text-center">
                <h2 className="max-w-4xl mx-auto mb-5 font-medium leading-tight font-heading text-9xl md:text-10xl xl:text-11xl">
                Start Using Pago for Secure Point-of-Sale (POS) Payments
                </h2>
              </div>
              <div className="max-w-6xl mx-auto">
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                The Pago Wallet can support many types of tokens and
                              currencies, including stable coins like USDC that match
                              the value of the US dollar. Pago also provides users
                              with rewards for using the Pago Wallet as loyalty tokens
                              from merchants and payment processors.
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                <strong>
                                Explore how Pago is changing the 
                                <a href="/">
                                  digital payment experience
                                </a>
                                , and download the 
                                <a href="/download-wallet">
                                  Pago Wallet 
                                </a>
                              </strong>
                              <strong>
                                
                                to join the blockchain payment revolution. Digital
                                payments have never been faster or simpler.
                              </strong>
                </p>
                <p className="max-w-4xl mb-5 text-xl leading-10 text-darkBlueGray-400">
                  If you’re a point-of-sale (POS) provider interested in
                              supporting secure payments with Pago, or want to learn
                              more about applications specific to your business, <a
                            href="/contact"
                            target="_blank">
                            get in touch</a>.
                </p>
                </div>
            </div>
        </section>
      </div>
    </>
  );
};

export default PointOfSalePayments;